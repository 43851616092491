import { useDrawerForm } from "@refinedev/antd";
import { List as AntdList, Button, Drawer, Form, InputNumber, Spin } from "antd";
import { useList } from "@refinedev/core";
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { ProductContext } from 'contexts/product'
import { IInventory } from 'interfaces/product'
import { IRegion } from 'interfaces/region'
import { IVendor } from 'interfaces/vendor'
import { useContext } from 'react'
import { INVENTORY_URL, REGION_URL, VENDOR_URL } from 'urls'
import { InventoryCard } from './InventoryCard'

export const InventoryList = () => {
  const product = useContext(ProductContext)
  const {
    data: inventoryData,
    refetch,
    isLoading,
  } = useList<IInventory>({
    resource: INVENTORY_URL,

    filters: [
      {
        field: 'product',
        operator: 'eq',
        value: product!.id,
      },
      {
        field: 'expand',
        operator: 'eq',
        value: 'vendor,region',
      },
    ]
  })

  const {
    drawerProps: inventoryCreateDrawerProps,
    formProps: inventoryCreateFormProps,
    show: showCreateInventoryDrawer,
    formLoading: inventoryCreateFormLoading,
  } = useDrawerForm({
    resource: INVENTORY_URL,
    action: 'create',
    redirect: false,
  })

  const {
    drawerProps: inventoryEditDrawerProps,
    formProps: inventoryEditFormProps,
    show: showEditInventoryDrawer,
    id: inventoryId,
    formLoading: inventoryEditFormLoading,
  } = useDrawerForm({
    resource: INVENTORY_URL,
    action: 'edit',
    redirect: false,
  })

  const editOrCreate = (edit: any, create: any) => (inventoryId ? edit : create)

  return (
    <>
      <AntdList
        dataSource={inventoryData?.data}
        loading={isLoading}
        loadMore={
          <div className="row h-center v-center">
            <Button onClick={() => showCreateInventoryDrawer()}>
              Add Inventory
            </Button>
          </div>
        }
        renderItem={(item) => (
          <InventoryCard
            item={item}
            showEdit={showEditInventoryDrawer}
            refetch={refetch}
          />
        )}
      />
      {/* Inventory create/edit form */}
      <Drawer
        {...editOrCreate(inventoryEditDrawerProps, inventoryCreateDrawerProps)}
        title="Inventory"
        destroyOnClose>
        {!(inventoryCreateFormLoading || inventoryEditFormLoading) ? (
          <Form
            {...editOrCreate(inventoryEditFormProps, inventoryCreateFormProps)}
            layout="vertical"
            preserve={false}
            onFinish={(values) => {
              editOrCreate(
                inventoryEditFormProps,
                inventoryCreateFormProps,
              ).onFinish({
                ...values,
                product: product!.id,
              })
            }}>
            <Form.Item
              label="Vendor"
              name="vendor"
              rules={[{ required: true }]}>
              <SelectWithDefault<IVendor>
                useSelectProps={{
                  resource: VENDOR_URL,
                  optionLabel: 'name',
                }}
              />
            </Form.Item>
            <Form.Item
              label="Region"
              name="region"
              rules={[{ required: true }]}>
              <SelectWithDefault<IRegion>
                useSelectProps={{
                  resource: REGION_URL,
                  optionLabel: 'name',
                }}
              />
            </Form.Item>
            <Form.Item label="Price" name="price">
              <InputNumber />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                onClick={() =>
                  editOrCreate(
                    inventoryEditFormProps,
                    inventoryCreateFormProps,
                  ).form.submit()
                }>
                Save
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <div className="row h-center v-center">
            <Spin />
          </div>
        )}
      </Drawer>
    </>
  )
}
