// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import {
  Button,
  Cascader,
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd'
import {
  BaseKey,
  useCreate,
  useDelete,
  useInvalidate,
  useList,
  useModal,
  useOne,
} from '@refinedev/core'
import { DateFieldFromNow } from 'components/field/DateFieldFromNow'
import { ILeadService, IService, ITracker } from 'interfaces/crm'
import { IStaff } from 'interfaces/staff'
import { useEffect, useState } from 'react'
import {
  LEAD_SERVICE_STATUS_URL,
  LEAD_SERVICE_URL,
  LEAD_URL,
  SERVICE_URL,
} from 'urls'
import { getUserName, isActualLoading, mergeArrays } from 'utils/common'

export const LeadServices: React.FC<{
  leadId: BaseKey
  isLeadRequirementService?: boolean
}> = ({ leadId, isLeadRequirementService = false }) => {
  const [mergedServices, setMergedServices] = useState<any>()
  const invalidate = useInvalidate()

  const { data: services, isLoading: servicesLoading } = useList<IService>({
    resource: SERVICE_URL,
    queryOptions: { enabled: true },
  })

  const {
    data: leadServices,
    isFetching: leadServicesFetching,
    isLoading: leadServicesLoading,
    fetchStatus: leadServicesFetchStatus,
    refetch,
  } = useOne<ILeadService[]>({
    resource: LEAD_URL,
    id: `${leadId}/get_services`,
    queryOptions: {
      enabled: true,
    },
  })

  const { data: serviceStatuses } = useOne<ITracker[]>({
    resource: LEAD_SERVICE_URL,
    id: 'status_list',
    queryOptions: {
      enabled: true,
    },
  })

  useEffect(() => {
    setMergedServices(
      mergeArrays(services?.data ?? [], leadServices?.data ?? [], 'id', [
        'service',
        'id',
      ]),
    )
  }, [services?.data, leadServices?.data])

  const { mutate } = useCreate()

  const { mutate: destroy } = useDelete()

  return (
    <div
      style={
        isLeadRequirementService
          ? {
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }
          : {}
      }>
      {servicesLoading || leadServicesLoading ? (
        <div className="row h-center v-center">
          <Spin />
        </div>
      ) : (
        mergedServices?.map((service: any) => (
          <div
            key={service.id}
            style={
              isLeadRequirementService
                ? {
                    width: '35vw',
                  }
                : {}
            }>
            <Row key={service.id} gutter={16} align="middle">
              <Col span={6}>
                <Typography.Text strong>{service.name}</Typography.Text>
              </Col>
              <Col span={10}>
                <Cascader
                  placeholder="Status"
                  displayRender={(label) => label[0]}
                  value={
                    service.statuses?.length
                      ? ([
                          service.statuses[0].status,
                          service.statuses[0].reason,
                        ] as any)
                      : null
                  }
                  loading={leadServicesFetching}
                  allowClear={!!service.statuses?.length}
                  onClear={() =>
                    destroy(
                      {
                        resource: LEAD_SERVICE_STATUS_URL,
                        id: (service.statuses as any)[0].id,
                      },
                      {
                        onSuccess: () => {
                          refetch()
                          invalidate({
                            resource: LEAD_URL,
                            invalidates: ['detail'],
                            id: `${leadId}/get_stage_changes`,
                          })
                        },
                      },
                    )
                  }
                  style={{ width: '100%' }}
                  options={serviceStatuses?.data?.map((status) => ({
                    label: status.name,
                    value: status.name,
                    children: status.options?.map((option) => ({
                      label: option,
                      value: option,
                    })),
                  }))}
                  onChange={(value) => {
                    mutate(
                      {
                        resource: LEAD_SERVICE_URL,
                        values: {
                          lead: leadId,
                          service:
                            (service.service as IService)?.id ?? service.id,
                          status: value[0],
                          reason: value[1] ?? '',
                        },
                      },
                      {
                        onSuccess: () => {
                          refetch()
                          invalidate({
                            resource: LEAD_URL,
                            invalidates: ['detail'],
                            id: `${leadId}/get_stage_changes`,
                          })
                        },
                      },
                    )
                  }}
                />
              </Col>
              <Col span={8}>
                {service.statuses?.length ? (
                  <>
                    <DateFieldFromNow value={service.statuses[0].updated_at} />{' '}
                    {!!service.statuses[0].updated_by && (
                      <>
                        by{' '}
                        {getUserName(
                          service.statuses[0].updated_by as IStaff,
                          false,
                        )}
                      </>
                    )}
                    {service.statuses[0].reason && (
                      <p>Reason: {service.statuses[0].reason}</p>
                    )}
                  </>
                ) : null}
              </Col>
            </Row>
            <Divider style={{ margin: 10 }} />
          </div>
        ))
      )}
    </div>
  )
}

export const ServicesModal = ({
  leadId,
  services: servicesFromProps,
  withLabel = false,
  showModal = false,
}: {
  leadId: BaseKey
  services?: ILeadService[]
  withLabel?: boolean
  showModal?: boolean
}) => {
  const { show, close, visible } = useModal()

  const [form] = Form.useForm()

  const {
    data: leadServices,
    isFetching: leadServicesFetching,
    isLoading: leadServicesLoading,
    fetchStatus: leadServicesFetchStatus,
    refetch,
  } = useOne<ILeadService[]>({
    resource: LEAD_URL,
    id: `${leadId}/get_services`,
    queryOptions: {
      enabled: false,
    },
  })

  useEffect(() => {
    if (visible) refetch()
  }, [visible])

  console.log('visible', visible)

  return (
    <Space>
      {!withLabel && (
        <Space direction="vertical">
          {isActualLoading(leadServicesLoading, leadServicesFetchStatus) ? (
            <Spin size="small" />
          ) : (
            (servicesFromProps ?? leadServices?.data)?.map((service) => (
              <div key={(service.service as IService)?.id}>
                {!!service.statuses?.length && (
                  <span>
                    ({service.statuses[0].status[0]})
                    {(service.service as IService)?.name}
                  </span>
                )}
              </div>
            ))
          )}
        </Space>
      )}
      <Button icon={<Icons.EditOutlined />} size="small" onClick={show}>
        {withLabel && 'Services'}
      </Button>
      <Modal
        onCancel={close}
        open={visible}
        title="Service Status"
        footer={null}
        destroyOnClose
        onOk={() => {
          form.submit()
        }}>
        <LeadServices leadId={leadId} />
      </Modal>
    </Space>
  )
}
