import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  DateField,
  ExportButton,
  getDefaultSortOrder,
  List,
  useTable,
} from '@refinedev/antd'

import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
} from 'antd'

import { useExport, useNavigation } from '@refinedev/core'
import { CheckAccess } from 'components/checkAccess'
import { PriceField } from 'components/field'
import { SelectWithDefault } from 'components/input/SelectWithDefault'

import dayjs from 'antd/node_modules/dayjs'
import type { Dayjs } from 'dayjs'
import { IPayout, IPayoutDetailed } from 'interfaces/payout'
import { RFQ_URL, SERVICE_URL, SHORTLIST_PACKAGE_URL } from 'urls'
import { convertStatusName, getPayoutStatusColor } from 'utils/common'
import { getSearchFormInitialValue } from 'utils/filter'

export const PayoutList: React.FC = () => {
  const tProps = useTable<
    IPayout,
    any,
    {
      search: string
      created_at: any[]
      event: string
      vendor: string
      status: string
      service: string
    }
  >({
    onSearch: ({ search, created_at, event, vendor, status, service }) => [
      {
        field: 'search',
        operator: 'eq',
        value: search,
      },
      {
        field: 'event',
        operator: 'eq',
        value: event,
      },
      {
        field: 'service',
        operator: 'eq',
        value: service,
      },
      {
        field: 'vendor',
        operator: 'eq',
        value: vendor,
      },
      {
        field: 'created_at',
        operator: 'between',
        value: created_at?.map((value) => value.format('YYYY-MM-DD')).join(','),
      },
      {
        field: 'status',
        operator: 'eq',
        value: status,
      },
    ],

    syncWithLocation: true,

    filters: {
      permanent: [{ field: 'expand', operator: 'eq', value: 'service' }],
    },

    sorters: {
      initial: [{ field: 'created_at', order: 'desc' }],
    },
  })

  const { edit, show } = useNavigation()

  const { triggerExport, isLoading: exportLoading } =
    useExport<IPayoutDetailed>({
      mapData: (item) => {
        return {
          id: item?.id,
          project_id: item?.event,
          amount: item?.amount,
          service: item?.service?.name,
          vendor: item?.vendor?.name,
          created_at: item?.created_at,
          updated_at: item?.updated_at,
          status: item?.status,
        }
      },

      pageSize: 100,
      filters: tProps.filters,
      sorters: tProps.sorter,
    })

  const pageHeaderProps = {
    extra: [
      <ExportButton
        key="export"
        onClick={triggerExport}
        loading={exportLoading}
      />,
    ],
  }

  return (
    <CheckAccess resource="access_payouts" action="">
      <List headerProps={pageHeaderProps}>
        <Row gutter={[4, 4]}>
          <Col lg={4} xs={24}>
            <Card>
              <Form
                {...tProps.searchFormProps}
                key="search"
                layout="vertical"
                size="small"
                initialValues={getSearchFormInitialValue(tProps.filters, {
                  created_at: (value: any) =>
                    value.map((date: string) => dayjs(date)),
                  vendor: (value: any) => Number(value),
                  status: (value: string) => value,
                })}>
                <Form.Item name="created_at" label="Created At">
                  <DatePicker.RangePicker
                    ranges={{
                      Today: [dayjs(), dayjs()],
                      'This Month': [
                        dayjs().startOf('month'),
                        dayjs().endOf('month'),
                      ],
                    }}
                  />
                </Form.Item>
                <Form.Item name="event" label="Project Id">
                  <Input allowClear />
                </Form.Item>
                <Form.Item name="service" label="Service">
                  <SelectWithDefault
                    useSelectProps={{
                      resource: SERVICE_URL,
                      optionLabel: 'name',
                    }}
                    allowClear
                  />
                </Form.Item>
                <Form.Item name="vendor" label="Vendor">
                  <SelectWithDefault
                    useSelectProps={{
                      resource: 'core/vendors',
                      optionLabel: 'name',
                    }}
                    allowClear
                  />
                </Form.Item>
                <Form.Item name="status" label="Status">
                  <Select
                    options={[
                      { label: 'Pending', value: 'pending' },
                      { label: 'Processing', value: 'processing' },
                      { label: 'Processed', value: 'processed' },
                      { label: 'Cancelled', value: 'cancelled' },
                      { label: 'Reversed', value: 'reversed' },
                      { label: 'Rejected', value: 'rejected' },
                    ]}
                    allowClear
                  />
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit">Go</Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
          <Col lg={20} xs={24}>
            <Table
              {...tProps.tableProps}
              size="small"
              rowKey="id"
              onRow={(record) => ({
                onClick: (event) => show('package_tool/payouts', record.id),
              })}>
              <Table.Column dataIndex="event" title="Project ID" ellipsis />
              <Table.Column<IPayout>
                dataIndex={['vendor', 'name']}
                title="Vendor"
                ellipsis
              />
              <Table.Column<IPayout>
                dataIndex="amount"
                title="Amount"
                sorter
                defaultSortOrder={getDefaultSortOrder('amount', tProps.sorter)}
                render={(value) => <PriceField value={value} />}
              />
              <Table.Column<IPayoutDetailed>
                dataIndex={['service', 'name']}
                title="Service"
              />
              <Table.Column<IPayoutDetailed>
                dataIndex="created_by"
                title="Created By"
                ellipsis
                render={(value) =>
                  `${value?.first_name} ${value?.last_name} (${value?.username})`
                }
              />
              <Table.Column
                dataIndex={['status']}
                title="Status"
                render={(status) => (
                  <Tag color={getPayoutStatusColor(status)}>
                    {convertStatusName(status)}
                  </Tag>
                )}
              />
              <Table.Column<IPayout>
                dataIndex="created_at"
                title="Created At"
                sorter
                ellipsis
                defaultSortOrder={getDefaultSortOrder(
                  'created_at',
                  tProps.sorter,
                )}
                render={(value) => <DateField value={value} format="LLL" />}
              />
              <Table.Column<IPayout>
                dataIndex="updated_at"
                title="Updated At"
                sorter
                ellipsis
                defaultSortOrder={getDefaultSortOrder(
                  'updated_at',
                  tProps.sorter,
                )}
                render={(value) => <DateField value={value} format="LLL" />}
              />
              <Table.Column
                dataIndex="package"
                title="Actions"
                ellipsis
                render={(value) =>
                  !!value && (
                    <Space onClick={(e) => e.stopPropagation()}>
                      <Tooltip title="Goto Package">
                        <Button
                          size="small"
                          icon={<FontAwesomeIcon icon={['fas', 'cubes']} />}
                          onClick={() => edit(SHORTLIST_PACKAGE_URL, value)}
                        />
                      </Tooltip>
                      <Tooltip title="Goto RFQ">
                        <Button
                          size="small"
                          icon={
                            <FontAwesomeIcon icon={['fas', 'laptop-house']} />
                          }
                          onClick={() => show(RFQ_URL, value)}
                        />
                      </Tooltip>
                    </Space>
                  )
                }
              />
            </Table>
          </Col>
        </Row>
      </List>
    </CheckAccess>
  )
}
