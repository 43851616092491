import { useModal } from '@refinedev/antd'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import {
  List as AntdList,
  Button,
  Card,
  Col,
  Form,
  FormProps,
  Input,
  Modal,
  Row,
  Space,
  Switch,
  Tabs,
  Typography,
} from 'antd'

import { BaseKey, useCan, useCreate, useMany, useUpdate } from '@refinedev/core'
import { AutoGeneratedPasswordInput } from 'components/input'
import { ICustomer } from 'interfaces/customer'
import { IStaff } from 'interfaces/staff'
import { useState } from 'react'
import { REGION_URL, VENDOR_URL } from 'urls'
import { generatePassword } from 'utils/common'
import { VendorPaymentsTab } from './PaymentsTab'
import { SelectWithDefault } from 'components/input/SelectWithDefault'

export const VendorForm: React.FC<{
  formProps: FormProps
  id?: BaseKey
  queryResult?: any
}> = ({ formProps, id, queryResult }) => {
  const [generatedPassword, setGeneratedPassword] = useState<string>('')

  const { data: membersData } = useMany<ICustomer>({
    resource: 'auth/users',
    ids: queryResult?.data?.data?.members,
    queryOptions: {
      enabled: !!queryResult?.data?.data?.members.length,
    },
  })

  const { modalProps, show, close } = useModal()

  const { mutate: vendorPost } = useCreate()

  const { mutate: changePassword, isLoading: changePasswordIsLoading } =
    useUpdate<IStaff>()

  const handleRemoveMember = (member_id: string) => {
    vendorPost(
      {
        resource: `core/vendors/${id}/remove_member`,
        values: {
          member_id,
        },
      },
      { onSuccess: () => queryResult.refetch() },
    )
  }

  const handleDefaultAccount = (bene_id: string) => {
    vendorPost(
      {
        resource: `${VENDOR_URL}/${id}/mark_account_default`,
        values: {
          beneficiary_id: bene_id,
        },
      },
      { onSuccess: () => queryResult.refetch() },
    )
  }

  const { data: payVendor } = useCan({
    resource: 'pay_vendor',
    action: '',
  })

  return (
    <Form {...formProps} layout="vertical">
      <Tabs>
        <Tabs.TabPane tab="Basic" key={'basic'}>
          <Form.Item label="Name" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="Cashfree Beneficiary ID" name="beneficiary_id">
            <Input />
          </Form.Item>
          <Form.Item label="Phone Number" name="phone_number">
            <Input />
          </Form.Item>
          <Form.Item label="Address" name="address">
            <Input.TextArea />
          </Form.Item>
          <Form.Item label="PAN Number" name="pan_number">
            <Input />
          </Form.Item>
          <Form.Item label="Region" name="region">
            <SelectWithDefault
              useSelectProps={{
                resource: REGION_URL,
                optionLabel: 'name',
              }}
            />
          </Form.Item>
        </Tabs.TabPane>
        {!!queryResult?.data?.data?.members.length && (
          <Tabs.TabPane tab="Members" key={'members'}>
            <Row>
              {membersData?.data.map((member) => (
                <Col key={member.id} xs={12} sm={8} md={6} lg={6} xl={6}>
                  <Card
                    actions={[
                      <Button
                        key="remove"
                        onClick={() => handleRemoveMember(member.id)}
                        icon={<Icons.DeleteOutlined />}
                        danger
                        size="small">
                        Remove
                      </Button>,
                      <Button
                        key="change password"
                        onClick={() => show()}
                        icon={<Icons.EditOutlined />}
                        size="small">
                        Password
                      </Button>,
                    ]}
                    title={member.username}>
                    <Space>
                      <Typography.Text>{member.first_name}</Typography.Text>
                      <Typography.Text>{member.last_name}</Typography.Text>
                    </Space>
                    <Modal
                      onOk={() => {
                        changePassword(
                          {
                            resource: 'core/vendors',
                            id: `${member.id}/change_password`,
                            values: { password: generatedPassword },
                          },
                          {
                            onSuccess: () => {
                              setGeneratedPassword('')
                              close()
                            },
                          },
                        )
                      }}
                      {...modalProps}
                      confirmLoading={changePasswordIsLoading}>
                      <Space direction="vertical">
                        <Typography.Title level={5}>
                          Generating password for {member.username}
                        </Typography.Title>
                        <AutoGeneratedPasswordInput
                          value={generatedPassword}
                          onChange={setGeneratedPassword}
                          onClick={() =>
                            setGeneratedPassword(generatePassword())
                          }
                        />
                      </Space>
                    </Modal>
                  </Card>
                </Col>
              ))}
            </Row>
          </Tabs.TabPane>
        )}
        {!!queryResult?.data?.data?.beneficiaries.length && (
          <Tabs.TabPane tab="Beneficiaries" key={'beneficiary'}>
            <AntdList
              dataSource={queryResult?.data?.data?.beneficiaries}
              renderItem={(item: any) => (
                <AntdList.Item
                  actions={[
                    <Switch
                      key="default"
                      disabled={item.is_default}
                      checked={item.is_default}
                      onChange={() => handleDefaultAccount(item.id)}
                    />,
                  ]}>
                  <AntdList.Item.Meta
                    title={item.account_type}
                    description={
                      item.account_type === 'vpa' ? (
                        <Typography.Text>
                          VPA: {item.vpa_address}
                        </Typography.Text>
                      ) : (
                        <p>
                          <Typography.Text>
                            Name: {item.account_name}
                          </Typography.Text>
                          <br />
                          <Typography.Text>IFSC: {item.ifsc}</Typography.Text>
                          <br />
                          <Typography.Text>
                            Account no: {item.account_number}
                          </Typography.Text>
                        </p>
                      )
                    }
                  />
                </AntdList.Item>
              )}
            />
          </Tabs.TabPane>
        )}
        {id && !!queryResult?.data?.data && payVendor?.can && (
          <Tabs.TabPane tab="Payments" key={'payments'}>
            <VendorPaymentsTab vendorId={id} />
          </Tabs.TabPane>
        )}
      </Tabs>
    </Form>
  )
}
