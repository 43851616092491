import type { AuthBindings } from '@refinedev/core'
import { AxiosInstance } from 'axios'

export const FLUTTER_TOKEN_KEY = 'flutter.authToken'
export const FLUTTER_REGION_KEY = 'flutter.region'
export const FLUTTER_FIRST_NAME_KEY = 'flutter.firstName'
export const FLUTTER_LAST_NAME_KEY = 'flutter.lastName'
export const FLUTTER_USER_ID_KEY = 'flutter.id'
export const FLUTTER_PERMISSIONS = 'flutter.permissions'

export const TOKEN_KEY = 'token'
export const USER_KEY = 'user'
export const REGION_KEY = 'region'
export const EXTERNAL_USER_KEY = 'is_external'
export const PERMISSIONS_KEY = 'permissions'
export const AUTH_URL = `${process.env.REACT_APP_API_URL}/auth/token/login/`

type AuthActionResponse = {
  success: boolean
  redirectTo?: string
  error?: Error
  [key: string]: unknown
}

type CheckResponse = {
  authenticated: boolean
  redirectTo?: string
  logout?: boolean
  error?: Error
}

type OnErrorResponse = {
  redirectTo?: string
  logout?: boolean
  error?: Error
}

export const authProvider = (httpClient: AxiosInstance): AuthBindings => {
  return {
    login: async ({ email, password }): Promise<AuthActionResponse> => {
      const request = new Request(AUTH_URL, {
        method: 'POST',
        body: JSON.stringify({ email, password }),
        headers: new Headers({ 'Content-Type': 'application/json' }),
      })
      const response = await fetch(request)
      const data = await response.json()

      if (response.ok) {
        if (!data.user.is_staff || !data.user.web_access) {
          throw new Error('Access Restricted')
        }
        localStorage.setItem(TOKEN_KEY, data.auth_token)
        localStorage.setItem(FLUTTER_TOKEN_KEY, JSON.stringify(data.auth_token))
        httpClient.defaults.headers = {
          // @ts-ignore
          Authorization: `Token ${data.auth_token}`,
        }

        localStorage.setItem(REGION_KEY, data.user.region)
        localStorage.setItem(
          FLUTTER_REGION_KEY,
          JSON.stringify(data.user.region),
        )
        localStorage.setItem(FLUTTER_USER_ID_KEY, JSON.stringify(data.user.id))
        localStorage.setItem(
          FLUTTER_FIRST_NAME_KEY,
          JSON.stringify(data.user.first_name),
        )
        localStorage.setItem(
          FLUTTER_LAST_NAME_KEY,
          JSON.stringify(data.user.last_name),
        )
        
        httpClient.interceptors.request.use((config) => {
          if (config.headers) {
            config.headers['X-Region'] = data.user.region
            config.headers['X-Source'] = 'studio_web'
          }
          return config
        })

        // set external user key in storage
        localStorage.setItem(EXTERNAL_USER_KEY, data.user.is_external)

        // return on successful login
        return {
          success: true,
          redirectTo: data.user.is_external ? '/cms/product-cards/' : '/',
        }
      }
      if (response.headers.get('content-type') !== 'application/json') {
        return {
          success: false,
          error: {
            message: 'Login Error',
            name: response.statusText,
          },
        }
      }

      const error = data.non_field_errors
      return {
        success: false,
        error: {
          message: 'Login Error',
          name: error || response.statusText,
        },
      }
    },
    logout: async () => {
      localStorage.clear()
      return {
        success: true,
        redirectTo: '/login',
      }
    },
    check: async (): Promise<CheckResponse> => {
      const token = localStorage.getItem('token')
      if (token) {
        httpClient.defaults.headers = {
          // @ts-ignore
          Authorization: `Token ${token}`,
        }

        return {
          authenticated: true,
        }
      }
      return {
        authenticated: false,
        logout: true,
        redirectTo: '/login',
        error: {
          message: 'Check failed',
          name: 'Unauthorized',
        },
      }
    },
    onError: async (error): Promise<OnErrorResponse> => {
      return {}
    },
    getIdentity: async () => {
      const token = localStorage.getItem(TOKEN_KEY)
      const response = await httpClient.get(
        `${process.env.REACT_APP_API_URL}/core/staff/me/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      )
      localStorage.setItem(USER_KEY, response.data.id)

      let regionId = localStorage.getItem(REGION_KEY)
      if (!regionId) {
        localStorage.setItem(REGION_KEY, response.data.region)
        regionId = response.data.region
      }

      httpClient.interceptors.request.use((config) => {
        if (config.headers) {
          config.headers['X-Region'] = regionId as string
        }
        return config
      })

      const permissionResponse = await httpClient.get(
        `${process.env.REACT_APP_API_URL}/core/staff/get_staff_permissions/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      )
      localStorage.setItem(PERMISSIONS_KEY, permissionResponse.data.permissions)
      localStorage.setItem(
        FLUTTER_PERMISSIONS,
        JSON.stringify(permissionResponse.data.permissions),
      )
      return response.data
    },
    getPermissions: async () => {
      return Promise.resolve()
    },
    forgotPassword: async ({
      email,
    }: {
      email: string
    }): Promise<AuthActionResponse> => {
      await httpClient.post(
        `${process.env.REACT_APP_API_URL}/core/staff/forgot_password/`,
        { email },
      )
      return {
        success: true,
      }
    },
    updatePassword: async (values: {
      email: string
      password: string
      otp: string
    }): Promise<AuthActionResponse> => {
      try {
        const response = await httpClient.post(
          `${process.env.REACT_APP_API_URL}/core/staff/forgot_password_change/`,
          values,
        )

        if (response.status !== 200) {
          return {
            success: false,
            error: {
              message: 'Error',
              name: response.data?.message,
            },
          }
        }

        return {
          success: true,
          redirectTo: '/login',
        }
      } catch (error: any) {
        return {
          success: false,
          error: {
            message: 'Error',
            name: error?.message,
          },
        }
      }
    },
  }
}
