import { useSelect } from '@refinedev/antd'
import {
  Button,
  Card,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Switch,
  Tabs,
} from 'antd'
import { file2Base64, useApiUrl, useCustom } from '@refinedev/core'
import { RowRemoveButton } from 'components/button'
import { useSelectWithSearch } from 'components/hooks/useSelectWithSearch'
import { SingleImageUpload } from 'components/input'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { DEFAULT } from 'consts'
import { IAttribute } from 'interfaces/attribute'
import { ICategory, ICategoryOption } from 'interfaces/category'
import { UseFormReturnType } from 'interfaces/refine'
import { useEffect, useState } from 'react'
import { INTERFACE_URL, SECTION_URL, SERVICE_URL } from 'urls'

export const CategoryForm: React.FC<{
  useFormProps: UseFormReturnType<ICategory>
}> = ({ useFormProps }) => {
  const apiUrl = useApiUrl()
  const [selectedCategory, setSelectedCategory] = useState<ICategory>()

  const { formProps, queryResult } = useFormProps

  const { selectProps, queryResult: categoryQueryResult } =
    useSelectWithSearch<ICategory>({
      resource: 'cms/category/',
      optionLabel: 'name',
      optionValue: 'id',
      fetchSize: 100,
    })

  const { selectProps: attributeSelectProps, queryResult: attributeData } =
    useSelect<IAttribute>({
      resource: 'core/attributes/',
      optionLabel: 'name',
      optionValue: 'id',
      fetchSize: 100,

      pagination: {
        mode: 'server',
      },
    })

  const { data } = useCustom<ICategoryOption>({
    url: `${apiUrl}/cms/category/`,
    method: 'options',
  })

  const options = data?.data.actions?.POST?.product_type.choices.map(
    (choice, i) => ({
      key: i,
      label: choice.display_name,
      value: choice.value,
    }),
  )

  // when the category list is fetched, set the selected category
  useEffect(() => {
    setSelectedCategory(
      categoryQueryResult.data?.data.find(
        (cat) => cat.id === queryResult?.data?.data.parent,
      ),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryQueryResult.isLoading])

  const onFinish = async (values: any) => {
    let coverImage = ''
    const { cover_image } = values

    // remove the cover_image key as it holds the s3 url or null
    // we dont want to patch with that
    delete values.cover_image

    if (cover_image) {
      const file = cover_image[cover_image?.length - 1]

      if (file.originFileObj) {
        const base64String = await file2Base64(file)
        coverImage = base64String
      }
    }

    const finalResponse = { ...values, parent: values.parent ?? null }
    if (coverImage.length) finalResponse['cover_image'] = coverImage

    return formProps.onFinish && formProps.onFinish(finalResponse)
  }

  return (
    <Form {...formProps} layout="vertical" onFinish={onFinish}>
      <Tabs>
        <Tabs.TabPane tab="Basic Info" key="Basic Info">
          <SingleImageUpload
            label="Cover Image"
            initialImageUrl={queryResult?.data?.data?.cover_image}
          />
          <Form.Item label="Name" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Order" name="order" initialValue={1}>
            <InputNumber />
          </Form.Item>
          <Form.Item label="Parent" name="parent">
            <Select
              {...selectProps}
              allowClear
              onClear={() => setSelectedCategory(undefined)}
              // @ts-expect-error
              onSelect={(value: string | number, options: any) => {
                setSelectedCategory(
                  categoryQueryResult.data?.data.find(
                    (category) => value === category.id,
                  ),
                )
              }}
            />
          </Form.Item>
          <Form.Item
            label="Hide from Filter"
            name="hide_from_filter"
            valuePropName="checked"
            tooltip={{
              title:
                'If this category should not be shown in the filters. Hides all the children as well',
            }}>
            <Switch />
          </Form.Item>
          <Form.Item
            label="Required for Consultation Tool"
            name="for_consultation_tool"
            valuePropName="checked"
            tooltip={{
              title:
                'if checked, this category will be shown in Consultation Tool',
            }}>
            <Switch />
          </Form.Item>
          <Form.Item label="Events" name="sections">
            <SelectWithDefault
              mode="multiple"
              useSelectProps={{ resource: SECTION_URL, optionLabel: 'name' }}
            />
          </Form.Item>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Product Info" key="Product Info">
          <Form.Item
            label="Has Quantity"
            name="has_quantity"
            valuePropName="checked">
            <Switch />
          </Form.Item>
          <Form.Item label="Product Type" name="product_type">
            <Select options={options} defaultValue={DEFAULT} />
          </Form.Item>
          <Form.Item label="Interface" name="interface">
            <SelectWithDefault
              useSelectProps={{ resource: INTERFACE_URL, optionLabel: 'name' }}
            />
          </Form.Item>
          <Form.Item label="Service" name="service">
            <SelectWithDefault
              useSelectProps={{ resource: SERVICE_URL, optionLabel: 'name' }}
            />
          </Form.Item>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Attributes Info" key="Attributes Info" forceRender>
          <Space direction="vertical" style={{ width: '100%' }}>
            {!!selectedCategory && (
              <Card title="Inherited Attributes">
                {selectedCategory?.combined_attributes.map((attribute) => (
                  <p key={attribute.id}>
                    {
                      attributeData.data?.data.find(
                        (att) => att.id === attribute.attribute,
                      )?.name
                    }
                  </p>
                ))}
              </Card>
            )}
            <Form.List name="attributes">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <Space
                      key={field.key}
                      style={{ width: '100%' }}
                      align="baseline">
                      <Form.Item {...field} name={[field.name, 'order']}>
                        <InputNumber />
                      </Form.Item>

                      <Form.Item {...field} name={[field.name, 'attribute']}>
                        <Select
                          {...attributeSelectProps}
                          dropdownStyle={{ minWidth: '50%' }}
                          style={{ minWidth: '50%' }}
                          placeholder="Select attribute"
                        />
                      </Form.Item>

                      <RowRemoveButton onClick={() => remove(field.name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block>
                      Add Attribute
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Space>
        </Tabs.TabPane>
      </Tabs>
    </Form>
  )
}
