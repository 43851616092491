export const SECTION_URL = 'package_tool/sections'

// cms
//  //  catalog
export const CATEGORY_URL = 'cms/category'
export const PRODUCT_URL = 'cms/product'
export const MATERIAL_URL = 'cms/materials'
export const INVENTORY_URL = 'cms/inventories'
export const MOODBOARDIMAGES_URL = 'cms/moodboard_images'
// // // order management
export const MATERIAL_INVENTORY_URL = 'cms/material_inventories'
export const WAREHOUSE_URL = 'supply_chain/warehouses'
export const STOCK_ITEM_URL = 'supply_chain/stock_items'
export const STOCK_ITEM_CHANGE_LOG_URL = 'supply_chain/stock_item_change_logs'
export const STOCK_ADJUSTMENT_URL = 'supply_chain/stock_adjustments'
export const PURCHASE_ORDER_URL = 'supply_chain/purchase_orders'
export const PURCHASE_ORDER_ITEM_URL = 'supply_chain/purchase_order_items'
//  //  pricing
export const COLOR_URL = 'cms/colors'
export const FLOWER_TYPE_URL = 'cms/flower_types'
export const FOLIAGE_TYPE_URL = 'cms/foliage_types'
export const FABRIC_TYPE_URL = 'cms/fabric_types'
export const FOLIAGE_RATIO_URL = 'cms/foliage_ratio'
export const ARTIFICIAL_RATIO_URL = 'cms/artificial_ratio'
export const FLOWER_URL = 'cms/flowers'
export const PRICING_ITEM_URL = 'cms/items'
export const PRICING_ITEM_MATERIAL_URL = 'cms/item_materials'
export const COMBINATION_URL = 'cms/combinations'
export const INTERFACE_URL = 'cms/interfaces'
export const BREAKDOWN_URL = 'cms/breakdowns'
export const BREAKDOWNVALUES_URL = 'cms/breakdownvalues'
export const FLORALVALUE_URL = 'cms/floral_value'

// // Catering
export const CUISINE_URL = 'cms/cuisines'
export const COURSE_URL = 'cms/courses'
export const DISH_URL = 'cms/dishes'
export const SURCHARGE_URL = 'cms/surcharges'
export const MENU_URL = 'cms/menus'
export const MENU_COURSE_URL = 'cms/menu_courses'
export const COURSE_DISH_URL = 'cms/course_dishes'
export const SESSION_URL = 'cms/sessions'
export const DIETARY_PREFERENCE_URL = 'cms/menus/dietary_preferences'

// core
export const ATTRIBUTE_URL = 'core/attributes'
export const TAG_URL = 'core/tags'
export const REGION_URL = 'core/regions'
export const STAFF_URL = 'core/staff'
export const VENDOR_URL = 'core/vendors'
export const GROUP_URL = 'core/groups'
export const LOG_URL = 'core/logs'
export const SETTINGS_URL = 'core/settings'

// // crm
export const STAGE_URL = 'core/crm/stages'
export const STATUS_URL = 'core/crm/statuses'
export const SERVICE_URL = 'core/crm/services'
export const LEAD_URL = 'core/crm/leads'
export const VENUE_LEAD_URL = 'package_tool/venue_lead'
export const PROGRESS_TRACKER_URL = 'core/crm/progress_trackers'
export const BOOKING_URL = 'core/crm/bookings'
export const BOOKING_SERVICE_URL = 'core/crm/booking_services'
export const LEAD_EVENT_URL = 'core/crm/lead_events'
export const LEAD_SERVICE_URL = 'core/crm/lead_services'
export const LEAD_SERVICE_STATUS_URL = 'core/crm/lead_service_statuses'
export const MEETING_URL = 'core/crm/meetings'
export const VENUE_REQUIREMENTS_URL = 'core/crm/venue_requirements'
export const VENUE_SUGGESTION_URL = 'core/crm/venue_suggestions'
export const VENUE_SUGGESTION_STATUS_URL = 'core/crm/venue_suggestion_statuses'
export const SALES_TARGET_VIEW_URL = 'core/crm/sales_target'
export const SAVED_FILTERS_URL = 'core/crm/saved_filters'
export const REPORT_SAVED_FILTER_URL = 'core/crm/report_saved_filters'
export const CALL_LOG_URL = 'core/crm/call_logs'
export const CRM_PREFERENCES_URL = 'core/crm/preferences'
export const CRM_UPDATE_QUIZ = 'core/crm/leads/update_quiz'

// // // Reports
export const PIPELINE_REPORT_URL = 'core/crm/reports/pipeline'
export const PIPELINE_DETAILED_REPORT_URL = 'core/crm/reports/pipeline_detailed'
export const SOURCE_REPORT_URL = 'core/crm/reports/source'
export const DAILY_LEAD_URL = 'core/crm/reports/daily_lead'
export const BOOKING_REPORT_URL = 'core/crm/reports/bookings'
export const SALES_TARGET_URL = 'core/crm/reports/sales'

// package tool
export const PROJECT_URL = 'package_tool/shortlist_package/projects'
export const PLAN_URL = 'package_tool/plans'
export const CELEBRATION_URL = 'package_tool/celebrations'
export const PACKAGE_URL = 'package_tool/packages'
export const CUSTOMER_URL = 'package_tool/customers'
export const SHORTLIST_PACKAGE_URL = 'package_tool/shortlist_packages'
export const VALIDATION_COMMENT_URL = 'package_tool/validation_comments'
export const CHANGE_TRACKER_URL = 'package_tool/change_trackers'
export const SHORTLIST_PACKAGE_DETAILS_URL =
  'package_tool/shortlist_package/package_details'
export const SHORTLIST_PACKAGE_DETAILS_NOTE_URL =
  'package_tool/shortlist_package/package_details_notes'
export const EXECUTED_IMAGE_URL =
  'package_tool/shortlist_package/executed_images'
export const SHORTLIST_LINE_ITEM_URL =
  'package_tool/shortlist_package/line_items'
export const ALTERNATE_ITME_URL =
  'package_tool/shortlist_package/alternate_items'
export const QUOTE_URL = 'package_tool/quotes'
export const RFQ_URL = 'package_tool/rfq'
export const ITEM_MATERIAL_URL = 'package_tool/item_materials'
export const PAYMENT_TYPE_URL = 'package_tool/payment_types'
export const BEST_FOR_URL = 'package_tool/best_for'
export const PAYMENT_URL = 'package_tool/payments'
export const PAYOUT_URL = 'package_tool/payouts'
export const PAYOUT_RULE_URL = 'package_tool/payout_rules'
export const MATERIAL_SOURCE_URL = 'package_tool/material_sources'
export const MATERIAL_SOURCE_NOTE_URL = 'package_tool/material_source_notes'
export const MATERIAL_STATUS_URL = 'package_tool/material_statuses'
export const MATERIAL_STATUS_MEDIA_URL = 'package_tool/material_status_medias'
export const MATERIAL_COMMENT_URL = 'package_tool/material_comments'
export const PACKAGE_DETAIL_MATERIAL_URL =
  'package_tool/package_details_materials'
export const LINE_ITEM_MATERIAL_URL = 'package_tool/line_item_materials'
export const LINE_ITEM_IMAGE_URL = 'package_tool/line_item_images'
// Venue
export const VENUE_URL = 'package_tool/venues'
export const CATERING_URL = 'package_tool/caterings'
export const ACCOMMODATION_URL = 'package_tool/accommodations'
export const KEY_LOCATION_URL = 'package_tool/key_locations'
export const VENUE_RENTAL_URL = 'package_tool/venue_rentals'
export const NEARBY_ACCOMMODATION_URL = 'package_tool/nearby_accommodations'
export const OVERALL_PACKAGE_URL = 'package_tool/overall_packages'
export const SERVICE_PACKAGE_URL = 'package_tool/service_packages'
export const MEDIA_SECTION_URL = 'package_tool/media_sections'
export const VENUE_MEDIA_URL = 'package_tool/venue_medias'
export const VENUE_AVAILABILITY_URL = 'package_tool/venue_availabilities'
export const SPACE_AVAILABILITY_URL = 'package_tool/space_availabilities'
// // Reports
export const EVENT_CALENDAR_URL = 'package_tool/reports/event_calendar'

// special
export const PRICING_MAIN_URL = 'cms/pricing'

// Timeline URL
export const TIMELINE_URL = 'package_tool/timeline/timelines'
export const TASK_URL = 'package_tool/tasks/tasks'

// Contributors URL
export const CONTRIBUTOR_URL = 'package_tool/shortlist/contributors'

export const MAX_MATERIAL_SOURCE = 'package_tool/max_material_sources'

export const PURCHASE_PACKAGE_MATERIAL =
  'package_tool/purchase_package_materials'

export const CHATS_URL = 'chats/'
