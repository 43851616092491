import { BaseKey } from '@refinedev/core'
import { ICustomer, IEvent } from './customer'
import { ISection } from './section'
import { IShortlistPackage } from './shortlistPackage'
import { IStaff } from './staff'
import { IVenue } from './venue'

export enum sourceOptions {
  WEDDINGWIRE_HYDERABAD = 'weddingwire_hyderabad',
  WEDDINGWIRE_BANGALORE = 'weddingwire_bangalore',
  WEDDINGWIRE_XCLUSIV_GOA = 'weddingwire_xclusiv_goa',
  WEDDINGWIRE_XCLUSIV_BANGALORE = 'weddingwire_xclusiv_bangalore',
  WEDDINGWIRE_XCLUSIV_JAIPUR = 'weddingwire_xclusiv_jaipur',
  WEDMEGOOD_BANGALORE = 'wedmegood_bangalore',
  WEDMEGOOD_HYDERABAD = 'wedmegood_hyderabad',
  WEDMEGOOD_XCLUSIV_BANGALORE = 'wedmegood_xclusiv_bangalore',
  WEDMEGOOD_XCLUSIV_HYDERABAD = 'wedmegood_xclusiv_hyderabad',
  WEDMEGOOD_XCLUSIV_JAIPUR = 'wedmegood_xclusiv_jaipur',
  WEDMEGOOD_XCLUSIV_GOA = 'wedmegood_xclusiv_goa',
  WEDMEGOOD_XCLUSIV_UDAIPUR = 'wedmegood_xclusiv_udaipur',
  PHONE = 'phone',
  WHATSAPP_INTERAKT = 'whatsapp_interakt',
  WEDDING_TALES = 'wedding_tales',
  REFERRAL = 'referral',
  WEDDINGBAZAAR = 'weddingbazaar',
  TIDIO = 'tidio',
  OFFLINE_VENUE_LEADS = 'offline_venue_leads',
  OFFLINE_VENUE_LEADS_CORPORATE = 'offline_venue_leads_corporate',
  INSTAGRAM_DM = 'instagram_dm',
  OTHERS = 'others',
}

export enum VenueType {
  STAR_HOTEL = '4-5 star Hotel',
  KALYANA_MANTAPAS = 'Kalyana Mantapas/ Convention Halls',
  RESORTS = 'Resorts',
  BANQUET = 'Banquet Hall',
  TEMPLE = 'Temple',
  HERITAGE = 'Heritage property',
  SMALL_FUNCTION = 'Small function/party halls',
  LUXURY_HOTEL = 'Luxury hotel',
  WEDDING_VENUE = 'Wedding venue',
}

export enum VenueAreaPreference {
  CENTRAL_BANGALORE = 'Central',
  EAST_BANGALORE = 'EAST',
  WEST_BANGALORE = 'WEST',
  NORTH_BANGALORE = 'NORTH',
  SOUTH_BANGALORE = 'SOUTH',
  OUTSIDE_CITY = 'Outside City',
  DESTINATION_WEDDING = 'Destination Wedding',
  FLEXIBLE = 'Flexible/No Preference',
}

export enum Cities {
  BANGALORE = 'Bangalore',
  HYDERABAD = 'Hyderabad',
  GOA = 'Goa',
  DELHI = 'Delhi',
}

export enum ServiceStatus {
  REQUIRED = 'Required',
  INACTIVE = 'Inactive',
  PIP = 'PIP',
  PITCHED = 'Pitched',
  BOOKED = 'Booked',
}

export enum MeetingPlace {
  ONLINE = 'Online',
  CUSTOMERS_HOUSE = "Customer's House",
  OFFICE = 'Office',
  VENUE = 'Venue',
  OTHERS = 'Others',
}

export enum MeetingAgenda {
  CATALOG_WALKTHROUGH = 'Catalog Walkthrough',
  FIRST_PROPOSAL = 'First Proposal Presentation',
  FOLLOW_UP = 'Follow Up Meeting',
  FOOD_TASTING = 'Food Tasting',
}

export enum MeetingPeople {
  BRIDE = 'Bride',
  GROOM = 'Groom',
  PARENTS = 'Parents',
  SIBLINGS = 'Siblings',
  RELATIVES = 'Relatives',
  OTHERS = 'Others',
}

export enum ReportNames {
  PIPELINE = 'Pipeline',
  PIPELINE_DETAILED = 'Pipeline Detailed',
  SOURCE = 'Source',
}

export interface ITracker {
  id: BaseKey
  name: string
  order: number
  is_reason_required: boolean
  is_date_required: boolean
  options: string[]
}

export interface ISalesTarget {
  id: BaseKey
  relationship_manger: number
  target: number
  date: string
}

export interface IMeeting {
  id: BaseKey
  lead_service: number
  date_time: string
  place: string
  people_present: string[]
  agenda: string
}

export interface IService {
  id: BaseKey
  name: string
}

export interface IProgressTracker {
  id: BaseKey
  change: string
  created_at: string
  updated_at: string
  created_by: BaseKey
  date: string
  reason?: string
  lead: BaseKey
  type: string
}

export interface ILead {
  id: BaseKey
  create_at: string
  updated_at: string
  name: string
  phone: string
  email: string
  pincode: string
  event_horizon: string
  source: string
  campaign: string
  ad_set: string
  estimated_value: number
  consultant: BaseKey
  customer: BaseKey
  project: BaseKey
  package: BaseKey
  project_value: number
  call_attempt: number
  call_attempt_date_time: string
  sales_call_attempt: number
  sales_call_attempt_date_time: string
  venue?: BaseKey
  booking_probability?: string
  expected_booking_date?: string
  city?: string
  could_not_collect_budget?: boolean
  budget: number
  relationship_manager: BaseKey
  profile?: ILeadProfile

  is_important: boolean
  is_quiz_filled: boolean
  is_existing: boolean
  is_venue_decided: boolean

  services?: ILeadService[]
  notes?: INotes[]
  stages?: IProgressTracker[]
  statuses?: IProgressTracker[]
}

export interface ILeadDetailed {
  id: BaseKey
  created_at: string
  updated_at: string
  name: string
  phone: string
  email: string
  pincode: string
  event_horizon: string
  source: string
  campaign: string
  ad_set: string
  estimated_value: number
  services_required: IService[]
  services_booked: IService[]
  statuses: IProgressTracker[]
  stages: IProgressTracker[]
  consultant: IStaff
  customer: ICustomer
  project: IEvent
  package: IShortlistPackage
  call_attempt: number
  call_attempt_date_time: string
  venues: IVenue[]
  event_dates: string[]
}

export interface INotes {
  lead: BaseKey
  user: IStaff | BaseKey
  text: string
  created_at: string
}

export interface ICallLog {
  id: BaseKey
  lead: BaseKey
  created_at: string
  updated_at: string
  user: BaseKey | IStaff
  call_status: string
  duration: number
  recording?: string
}

export interface ILeadServiceStatus {
  id: BaseKey
  lead_service: BaseKey
  status: string
  updated_at: Date
  updated_by: BaseKey | IStaff
}

export interface ILeadService {
  id: BaseKey
  lead: BaseKey
  service: BaseKey | IService
  estimated_value: number
  statuses?: ILeadServiceStatus[]
  booking_probability?: 'high' | 'medium' | 'low'
  expected_booking_date?: string
  consultant: IStaff
}

export interface ILeadEvent {
  id: BaseKey
  lead: BaseKey
  event: BaseKey | ISection
  date_time: string
  value: number
  pax: number
  food_preference?: string
  liquor?: boolean
  music?: string
  accommodation_required?: string
  venue_type?: VenueType[]
  area_preference?: string
  notes?: string
}

export interface IBookingService {
  id: BaseKey
  service: BaseKey | IService
  amount: number
}

export interface IBooking {
  id: BaseKey
  date: string
  services: IBookingService[]
}

export interface IBookingSummary {
  month: number
  total: number
}

export interface IVenueRequirement {
  id: BaseKey
  events: string
  dates: string
  venue_type: string
  area_preference: string
  pax: number
  notes: string
  venue_budget: number
  lead: number
  event_name: string
  city: string
  has_nearby_accomodation: boolean
  event: BaseKey
  is_venue_decided: boolean
  venue: BaseKey
  breakfast_pax: number
  breakfast_event: BaseKey
  breakfast_catering: string
  lunch_pax: number
  lunch_event: BaseKey
  lunch_catering: string
  high_tea_pax: number
  high_tea_event: BaseKey
  high_tea_catering: string
  dinner_pax: number
  dinner_event: BaseKey
  dinner_catering: string
  accomodation_pax: number
}


export interface IVenueSuggestionStatus {
  id: BaseKey
  venue_suggestion: BaseKey
  status: string
  created_by: number
}

export interface IVenueSuggestion {
  id: BaseKey
  venue_req: number
  venue: any
  shared_with_customer: boolean
  shared_with_venue: boolean
  venue_visit: boolean
  quote_sent: boolean
  booked: boolean
  statuses?: IVenueSuggestionStatus[]
  is_shared_with_venue: boolean
}

export interface ISavedFilter {
  id: BaseKey
  name: string
  value: string
}

export interface IReportSavedFilter {
  id: BaseKey
  name: string
  value: string
  report: string
  type?: string
}

export interface ILeadQuizData {
  quiz_package_details?: IQuizPackageDetails[]
  quiz_gaust_count: IQuizGuestPaxCount[][]
  json_data: string
  id: BaseKey
  phone: string
  venue?: string
  budget?: string[]
  event?: string[]
  venue_decided: boolean
  service?: string[]
  event_date?: string[]
  venue_type?: string[]
  pax?: string[]
  area?: string[]
  lead: BaseKey
  decor_options?: string
}

export interface ILeadProfile {
  id: BaseKey
  lead: BaseKey
  location?: string
  headline?: string
  summary?: string
  linkedin_url?: string
  job_title?: string
  company_name?: string
  company_industry?: string
  education_institute?: string
  education_field?: string
  skills?: string[]
  languages?: string[]
  photo?: string
  age?: string
  gender?: string
  location_type?: string
  professional_level?: string
}

export interface IQuizPackageDetails {
  day: string
  event: string
  guests: string
  time: string
}

export interface IQuizGuestPaxCount {
  day: string
  meal: string
  count: string
  guestCountData: string
}
