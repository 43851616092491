import { InboxOutlined } from '@ant-design/icons'
import {
  DeleteButton,
  ImageField,
  useModalForm,
  useSimpleList,
} from '@refinedev/antd'
import { useApiUrl } from '@refinedev/core'
import {
  Button,
  Collapse,
  Descriptions,
  Form,
  Input,
  List,
  Modal,
  Space,
  UploadProps,
} from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import { REGION_KEY, TOKEN_KEY } from 'authProvider'
import { IMediaSection, IVenueMedia, MediaType } from 'interfaces/venue'
import { IoRefresh } from 'react-icons/io5'
import ReactPlayer from 'react-player/file'
import { VENUE_MEDIA_URL } from 'urls'

// get accept value from media type
function getAcceptValue(mediaType: MediaType): string {
  switch (mediaType) {
    case MediaType.IMAGE:
      return 'image/*'
    case MediaType.VIDEO:
      return 'video/*'
    case MediaType.DOCUMENT:
      return '.pdf,.doc,.docx,.txt'
    default:
      return ''
  }
}

export const MediaSectionDetails = ({ item }: { item: IMediaSection }) => {
  const apiUrl = useApiUrl()

  const authToken = localStorage.getItem(TOKEN_KEY)
  const region = localStorage.getItem(REGION_KEY)

  const props: UploadProps = {
    name: 'file',
    multiple: true,
    accept: getAcceptValue(item.media_type),
    action: `${apiUrl}/${VENUE_MEDIA_URL}/`,
    data: (file) => ({ media: file, media_section: item.id }),
    headers: {
      Authorization: `Token ${authToken}`,
      'X-Region': region || '',
    },
  }

  const { listProps, queryResult } = useSimpleList<IVenueMedia>({
    resource: VENUE_MEDIA_URL,
    filters: {
      permanent: [{ field: 'media_section', operator: 'eq', value: item.id }],
    },
  })

  const { modalProps, formProps, show } = useModalForm<IVenueMedia>({
    resource: VENUE_MEDIA_URL,
    action: 'create',
    redirect: false,
  })

  return (
    <div>
      <Descriptions column={4}>
        <Descriptions.Item label="Order">{item.order}</Descriptions.Item>
        <Descriptions.Item label="Name">{item.name}</Descriptions.Item>
        <Descriptions.Item label="Media Type">
          {item.media_type}
        </Descriptions.Item>
      </Descriptions>
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibited from
          uploading company data or other banned files.
        </p>
      </Dragger>
      <Button onClick={() => show()}>Add link</Button>
      <Modal {...modalProps}>
        <Form
          {...formProps}
          onFinish={(values) =>
            formProps.onFinish &&
            formProps.onFinish({ ...values, media_section: item.id })
          }>
          <Form.Item label="Link" name={'link'}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Collapse>
        <Collapse.Panel
          header={`Uploaded files (${
            queryResult.data?.data.filter((item) => item.media).length
          })`}
          key={'uploaded+media'}
          extra={
            <Button
              icon={<IoRefresh />}
              onClick={(e) => {
                e.stopPropagation()
                queryResult.refetch()
              }}
            />
          }>
          <List
            {...listProps}
            grid={{ gutter: 16, column: 6 }}
            renderItem={(venueMedia) =>
              item.media_type === MediaType.IMAGE ? (
                <ImageField value={venueMedia.media} />
              ) : (
                <ReactPlayer
                  pip={false}
                  config={{}}
                  controls
                  url={venueMedia.media}
                />
              )
            }
          />
        </Collapse.Panel>
        <Collapse.Panel
          header={`Links ${
            queryResult.data?.data.filter((item) => item.link).length
          }`}
          key="l;inks">
          <List
            dataSource={queryResult.data?.data.filter((item) => item.link)}
            renderItem={(venueMedia) => (
              <Space>
                <a href={venueMedia.link}>{venueMedia.link}</a>{' '}
                <DeleteButton
                  resource={VENUE_MEDIA_URL}
                  recordItemId={venueMedia.id}
                />
              </Space>
            )}
          />
        </Collapse.Panel>
      </Collapse>
    </div>
  )
}
