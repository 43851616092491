import { Form, FormProps, Input } from "antd";
import { BaseKey } from "@refinedev/core";

export const ServiceForm: React.FC<{
  formProps: FormProps
  id?: BaseKey
  queryResult?: any
}> = ({ formProps, id, queryResult }) => {
  return (
    <Form {...formProps} layout="vertical">
      <Form.Item name="name" label="Name">
        <Input />
      </Form.Item>
    </Form>
  )
}
