import React from 'react'
import { Radio, Select, Form, Card } from 'antd'
import { DatePicker } from 'components/input'
import dayjs from 'dayjs'
import { Descriptions } from 'antd/lib'

export interface OptionProps {
  title: string
  value: string
}

export interface InfoProps {
  title: string
  value: any
}

export interface QuestionProps {
  index?: string
  question: string
  type:
    | 'radio'
    | 'singleSelect'
    | 'datePicker'
    | 'multiQuestion'
    | 'multiSelect'
  options?: OptionProps[]
  picker?: 'month' | 'week' | 'year'
  answer?: any
  subQuestions?: QuestionProps[]
  repeatable?: boolean
  enableSearch?: boolean
  repeatCount?: number
  onChange?: (value: any) => void
  onSearch?: (value: any) => void
  onSubChange?: (repeatIndex: number, subIndex: number, value: any) => void
  info?: InfoProps[][] | undefined
}

export const Question: React.FC<QuestionProps> = ({
  index,
  question,
  type,
  options,
  picker,
  answer,
  subQuestions,
  onChange,
  onSearch,
  repeatCount,
  enableSearch,
  onSubChange,
  info,
}) => {
  let inputComponent

  switch (type) {
    case 'radio':
      inputComponent = (
        <Radio.Group
          onChange={(e) => onChange && onChange(e.target.value)}
          value={answer}>
          {options?.map((option) => (
            <Radio.Button key={option.value} value={option.value}>
              {option.title}
            </Radio.Button>
          ))}
        </Radio.Group>
      )
      break

    case 'singleSelect':
      inputComponent = (
        <Select
          showSearch={enableSearch}
          onChange={(value) => onChange && onChange(value)}
          onSearch={(value) => onSearch && onSearch(value)}
          filterOption={(input, option) =>
            (option?.value ?? '').toString().toLowerCase().includes(input.toLowerCase())
          }
          value={answer}>
          {options?.map((option) => (
            <Select.Option key={option.value} value={option.value}>
              {option.title}
            </Select.Option>
          ))}
        </Select>
      )
      break

    case 'multiSelect':
      inputComponent = (
        <Select
          mode="multiple"
          showSearch={enableSearch}
          onChange={(value) => onChange && onChange(value)}
          onSearch={(value) => onSearch && onSearch(value)}
          filterOption={(input, option) =>
            (option?.title ?? '').toLowerCase().includes(input.toLowerCase())
          }
          value={answer || []}>
          {options?.map((option) => (
            <Select.Option key={option.value} value={option.value}>
              {option.title}
            </Select.Option>
          ))}
        </Select>
      )
      break

    case 'datePicker':
      inputComponent = (
        <DatePicker
          picker={picker}
          onChange={(date: any, dateString: any) =>
            onChange && onChange(dateString)
          }
          format="YYYY-MM"
          value={answer ? dayjs(answer) : null}
        />
      )
      break

    case 'multiQuestion':
      inputComponent = (
        <div>
          {Array.from({ length: repeatCount || 1 }).map((_, repeatIndex) => (
            <Card key={repeatIndex} style={{ marginBottom: '10px' }}>
              <div key={repeatIndex}>
                <h4>
                  {question} - {repeatIndex + 1}
                </h4>
                {subQuestions?.map((subQuestion, subIndex) => (
                  <div key={subIndex}>
                    <Question
                      index={`${repeatIndex}_${subIndex}`}
                      question={subQuestion.question}
                      type={subQuestion.type}
                      options={subQuestion.options}
                      answer={answer[repeatIndex]?.[subIndex] || ''}
                      onChange={(value) =>
                        onSubChange && onSubChange(repeatIndex, subIndex, value)
                      }
                    />
                  </div>
                ))}
              </div>
            </Card>
          ))}
        </div>
      )
      break

    default:
      inputComponent = null
  }

  return (
    <Form.Item label={question}>
      {inputComponent}
      {info && (
        <div style={{ marginTop: '10px' }}>
          {info.map((infoRow, rowIndex) => (
            <Card key={rowIndex}>
              <Descriptions
                key={rowIndex}
                column={1}
                style={{ marginBottom: '5px' }}>
                {infoRow.map((infoItem, infoIndex) => (
                  <Descriptions.Item key={infoIndex} label={infoItem.title}>
                    {infoItem.value}
                  </Descriptions.Item>
                ))}
              </Descriptions>
            </Card>
          ))}
        </div>
      )}
    </Form.Item>
  )
}

export default Question
