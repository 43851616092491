import { ImageField, useModal, useSimpleList } from '@refinedev/antd'
import {
  Authenticated,
  BaseKey,
  useList,
  useOne,
  useRouterContext,
} from '@refinedev/core'
import {
  Layout as AntdLayout,
  Button,
  Card,
  Col,
  Descriptions,
  Image,
  List,
  Modal,
  Row,
  Space,
  Spin,
  Table,
  Tabs,
  Tag,
  Typography,
} from 'antd'
import { EXTERNAL_USER_KEY } from 'authProvider'
import { PriceField } from 'components/field'
import { logEvent } from 'firebase/analytics'
import parse from 'html-react-parser'
import { IAttributeValueDetail } from 'interfaces/attribute'
import { ISetting } from 'interfaces/base'
import { IMenu, ISession } from 'interfaces/catering'
import { IExecutedImage, IShortlistPackage } from 'interfaces/shortlistPackage'
import {
  IKeyLocation,
  IMediaSection,
  INearbyAccommodation,
  IOverallPackage,
  IServicePackage,
  IVenue,
  IVenueMedia,
  IVenueRental,
  MediaType,
  ServicePackageType,
} from 'interfaces/venue'
import qs from 'qs'
import { Key, ReactElement, useEffect, useState } from 'react'
import { IoLocationOutline } from 'react-icons/io5'
import Masonry from 'react-masonry-css'
import ReactPlayer from 'react-player'
import { analytics } from 'services/firebase'
import {
  COURSE_DISH_URL,
  EXECUTED_IMAGE_URL,
  KEY_LOCATION_URL,
  MEDIA_SECTION_URL,
  MENU_COURSE_URL,
  MENU_URL,
  NEARBY_ACCOMMODATION_URL,
  OVERALL_PACKAGE_URL,
  PRODUCT_URL,
  SERVICE_PACKAGE_URL,
  SETTINGS_URL,
  SHORTLIST_PACKAGE_URL,
  VENUE_RENTAL_URL,
  VENUE_URL,
} from 'urls'
import {
  VenueAddressCard,
  VenueKeyLocationCard,
  cleanTimeAndDistance,
} from '../VenueShowcaseCards'
import './styles.less'

const { Title, Text } = Typography

const isExternal = localStorage.getItem(EXTERNAL_USER_KEY)

export const VenueDetail: React.FC = () => {
  const hideHeader = !!qs.parse(
    window.location.search.substring(1), // remove first ? character
  )['hideHeader']

  return (
    <Authenticated>
      <AntdLayout className="showcase">
        {!hideHeader && (
          <AntdLayout.Header className="showcase-header">
            <img src={'/meragi-logo.svg'} alt="Meragi" />
          </AntdLayout.Header>
        )}
        <AntdLayout.Content
          className="showcase-content"
          style={hideHeader ? { paddingTop: 0 } : {}}>
          <VenueShowcaseDetail />
        </AntdLayout.Content>
      </AntdLayout>
    </Authenticated>
  )
}

export const VenueShowcaseDetail = () => {
  const { useParams } = useRouterContext()
  const { id } = useParams<any>()
  const { data, isLoading } = useOne<IVenue>({
    resource: VENUE_URL,
    id: id,
  })

  const venue = data?.data

  const { data: settingsData } = useOne<ISetting>({
    resource: SETTINGS_URL,
    id: 1,
  })

  const { data: keyLocations } = useList<IKeyLocation>({
    resource: KEY_LOCATION_URL,
    filters: [{ field: 'venue', operator: 'eq', value: id }],
  })

  const { data: venueRentals } = useList<IVenueRental>({
    resource: VENUE_RENTAL_URL,
    filters: [{ field: 'venue', operator: 'eq', value: id }],
  })

  const { data: nearbyAccommodation } = useList<INearbyAccommodation>({
    resource: NEARBY_ACCOMMODATION_URL,
    filters: [
      { field: 'venue', operator: 'eq', value: id },
      { field: 'expand', operator: 'eq', value: 'venue_option' },
    ],
  })

  const { data: overallPackages, isLoading: isOverallPackagesLoading } =
    useList<IOverallPackage>({
      resource: OVERALL_PACKAGE_URL,
      filters: [
        { field: 'venue', operator: 'eq', value: id },
        { field: 'expand', operator: 'eq', value: 'venue_option' },
      ],
    })

  const { data: spacesList } = useList({
    resource: PRODUCT_URL,
    filters: [
      { field: 'space_in_venue', operator: 'in', value: id },
      { field: 'is_deleted', operator: 'eq', value: false },
      { field: 'is_space', operator: 'eq', value: true },
    ],
  })

  const { data: servicePackages, isLoading: isServicePackagesLoading } =
    useList<IServicePackage>({
      resource: SERVICE_PACKAGE_URL,
      filters: [{ field: 'venue', operator: 'eq', value: id }],
    })

  useEffect(() => {
    const startTime = Date.now()
    const venueId = id
    const venueName = venue?.name

    return () => {
      const endTime = Date.now()
      const timeSpent = endTime - startTime // Time spent in milliseconds
      logEvent(analytics, 'page_time_spent', {
        venue_id: venueId,
        venue_name: venueName,
        time_spent: timeSpent,
      })
    }
  }, [id])

  return (
    <Card loading={isLoading} className="venue-showcase">
      <VenueTitleSection venue={venue} />
      <VenueMedia venueId={id} />
      <Tabs type="card" className="custom-tabs-venue">
        <Tabs.TabPane tab="Basic Info" forceRender>
          {parse(venue?.description ?? '')}
          <Space
            direction="vertical"
            size="small"
            style={{
              display: 'flex',
              flex: 1,
            }}>
            <div style={{ display: 'flex', gap: '16px' }}>
              <div style={{ flex: '1 1 40%' }}>
                <ImageField
                  value={venue?.map_image}
                  height={400}
                  loading="eager"
                  prefixCls="custom-image-preview"
                  style={{ borderRadius: 10, width: '100%' }}
                  onClick={() =>
                    logEvent(analytics, 'map_image_click', { venue_id: id })
                  }
                />
              </div>
              <div style={{ flex: '1 1 60%' }}>
                <div>
                  <VenueAddressCard
                    line_1={venue?.line_1}
                    line_2={venue?.line_2}
                    city={venue?.city}
                    state={venue?.state}
                    pincode={venue?.pincode}
                  />
                </div>
                <div>
                  {keyLocations?.data.map((keyLocation) => (
                    <VenueKeyLocationCard
                      key={keyLocation.id}
                      keyLocation={keyLocation as IKeyLocation}
                    />
                  ))}
                </div>
              </div>
            </div>

            <Card
              bordered={false}
              style={{ boxShadow: 'none', backgroundColor: '#fffaf0' }}>
              <Title
                level={3}
                style={{ textAlign: 'center', color: '#6533cb' }}>
                Key Information
              </Title>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '16px',
                  alignItems: 'stretch',
                }}>
                <div style={{ flex: '1 1 30%' }}>
                  <Card
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      boxShadow: '#dbdbdb 0px 0px 9px 0px',
                      minHeight: '160px',
                      justifyContent: 'center',
                    }}>
                    <Title level={4} style={{ color: '#c82f7b' }}>
                      Venue Rental
                    </Title>
                    {venueRentals?.data.map((venueRental) => (
                      <div key={venueRental.id}>
                        <span>
                          {venueRental.name ?? ''} {venueRental.from_hr} Hours{' '}
                          {!!venueRental.to_hr &&
                            ` - ${venueRental.to_hr} Hours`}{' '}
                          -{' '}
                          <PriceField
                            showDecimal={false}
                            value={venueRental.price ?? 0}
                          />
                        </span>
                      </div>
                    ))}
                  </Card>
                </div>
                <div style={{ flex: '1 1 30%' }}>
                  <Card
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      boxShadow: '#dbdbdb 0px 0px 9px 0px',
                      minHeight: '160px',
                      justifyContent: 'center',
                    }}>
                    <Title level={4} style={{ color: '#c82f7b' }}>
                      Capacity
                    </Title>
                    <div>Seating Capacity: {venue?.seating_capacity}</div>
                    <div>Floating Capacity: {venue?.floating_capacity}</div>
                  </Card>
                </div>
                <div style={{ flex: '1 1 30%' }}>
                  <Card
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      boxShadow: '#dbdbdb 0px 0px 9px 0px',
                      minHeight: '160px',
                      justifyContent: 'center',
                    }}>
                    <Title level={4} style={{ color: '#c82f7b' }}>
                      Accommodation
                    </Title>
                    {!!venue?.accommodation.length ? (
                      <Space direction="vertical" align="center">
                        <div>
                          {venue?.accommodation.reduce(
                            (a, b) => a + (b.number_of_rooms ?? 0),
                            0,
                          )}{' '}
                          Rooms
                        </div>
                        <div>
                          <PriceField
                            showDecimal={false}
                            value={venue?.accommodation[0].price ?? 0}
                          />
                          /night
                        </div>
                      </Space>
                    ) : (
                      '-'
                    )}
                  </Card>
                </div>
                <div style={{ flex: '1 1 30%' }}>
                  <Card
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      boxShadow: '#dbdbdb 0px 0px 9px 0px',
                      minHeight: '160px',
                      justifyContent: 'center',
                    }}>
                    <Title level={4} style={{ color: '#c82f7b' }}>
                      Nearby Accommodation
                    </Title>
                    {!!nearbyAccommodation?.total
                      ? nearbyAccommodation.data.map((na) => (
                          <div key={na.id}>
                            {(na.venue_option as IVenue)?.name ||
                              na.venue_option_text}
                          </div>
                        ))
                      : '-'}
                  </Card>
                </div>
                <div style={{ flex: '1 1 30%' }}>
                  <Card
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      boxShadow: '#dbdbdb 0px 0px 9px 0px',
                      minHeight: '160px',
                      justifyContent: 'center',
                    }}>
                    <Title level={4} style={{ color: '#c82f7b' }}>
                      Catering
                    </Title>
                    {!!venue?.catering_in_house && 'In House'}
                    {venue?.catering_in_house &&
                      venue?.catering_out_house &&
                      ' & '}
                    {!!venue?.catering_out_house && 'Out House'}
                    {!!venue?.catering.length && (
                      <div>
                        In house starting from{' '}
                        <PriceField
                          showDecimal={false}
                          value={venue?.catering[0].veg_price ?? 0}
                        />{' '}
                        for Veg &{' '}
                        <PriceField
                          showDecimal={false}
                          value={venue?.catering[0].non_veg_price ?? 0}
                        />{' '}
                        for Non Veg
                      </div>
                    )}
                  </Card>
                </div>
                <div style={{ flex: '1 1 30%' }}>
                  <Card
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      boxShadow: '#dbdbdb 0px 0px 9px 0px',
                      minHeight: '160px',
                      justifyContent: 'center',
                    }}>
                    <Title level={4} style={{ color: '#c82f7b' }}>
                      Parking
                    </Title>
                    {!venue?.parking_available && 'Not Available'}
                    {venue?.parking_car_count && (
                      <div>For {venue?.parking_car_count} Cars</div>
                    )}
                    {venue?.valet_available && 'Valet Available'}
                  </Card>
                </div>
              </div>
            </Card>
            {!!venue?.accommodation.length && (
              <Card
                bordered={false}
                style={{ boxShadow: 'none', backgroundColor: '#fffaf0' }}>
                <Title
                  level={3}
                  style={{ textAlign: 'center', color: '#6533cb' }}>
                  Accommodation
                </Title>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
                  <div
                    style={{
                      flex: '1 1 30%',
                    }}>
                    <Card
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        boxShadow: '#dbdbdb 0px 0px 9px 0px',
                        minHeight: '160px',
                        justifyContent: 'center',
                      }}>
                      <Title level={4} style={{ color: '#c82f7b' }}>
                        Accommodation at venue
                      </Title>
                      {venue?.accommodation.reduce(
                        (a, b) => a + (b.number_of_rooms ?? 0),
                        0,
                      )}{' '}
                      Rooms
                    </Card>
                  </div>
                  <div
                    style={{
                      flex: '1 1 30%',
                    }}>
                    <Card
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        boxShadow: '#dbdbdb 0px 0px 9px 0px',
                        minHeight: '160px',
                        justifyContent: 'center',
                      }}>
                      <Title level={4} style={{ color: '#c82f7b' }}>
                        Accommodation included in Rental Price
                      </Title>
                      {venue?.accommodation
                        .filter((acc) => acc.is_included_in_rental)
                        .reduce((a, b) => a + (b.number_of_rooms ?? 0), 0)}{' '}
                      Rooms
                    </Card>
                  </div>
                  <div
                    style={{
                      flex: '1 1 30%',
                    }}>
                    <Card
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        boxShadow: '#dbdbdb 0px 0px 9px 0px',
                        minHeight: '160px',
                        justifyContent: 'center',
                      }}>
                      <Title level={4} style={{ color: '#c82f7b' }}>
                        Rooms Starting Price
                      </Title>
                      <PriceField
                        showDecimal={false}
                        value={
                          venue?.accommodation.sort(
                            (a, b) => a.price - b.price,
                          )[0].price
                        }
                      />
                    </Card>
                  </div>
                </div>
              </Card>
            )}

            {!!nearbyAccommodation?.total && (
              <Card
                bordered={false}
                style={{ boxShadow: 'none', backgroundColor: '#fffaf0' }}>
                <Title
                  level={3}
                  style={{ textAlign: 'center', color: '#6533cb' }}>
                  Nearby Accommodation
                </Title>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
                  {nearbyAccommodation.data.map((na) => (
                    <div
                      key={na.id}
                      style={{
                        flex: '1 1 30%',
                      }}>
                      <Card
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          textAlign: 'center',
                          boxShadow: '#dbdbdb 0px 0px 9px 0px',
                          minHeight: '160px',
                          justifyContent: 'center',
                        }}>
                        <Title level={4} style={{ color: '#c82f7b' }}>
                          {(na.venue_option as IVenue)?.name ||
                            na.venue_option_text}
                        </Title>
                        {na.price_on_enquiry ? (
                          'Available on Enquiry'
                        ) : (
                          <>
                            <PriceField
                              showDecimal={false}
                              value={na.price ?? 0}
                            />{' '}
                            /night
                          </>
                        )}

                        <div>
                          {cleanTimeAndDistance(na.time, na.distance)} away
                        </div>
                      </Card>
                    </div>
                  ))}
                </div>
              </Card>
            )}
          </Space>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Spaces" key="spaces">
          <List
            dataSource={spacesList?.data}
            renderItem={(item) => (
              <Card
                bordered={false}
                style={{
                  marginBottom: '10px',
                  background: 'transparent',
                  border: '1px solid #f68e24',
                }}>
                <Title level={3}>{item?.name}</Title>
                <div style={{}}>
                  <Masonry
                    breakpointCols={{
                      default: 5,
                      2560: 5,
                      1920: 5,
                      1600: 4,
                      1200: 5,
                      992: 4,
                      768: 3,
                    }}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column">
                    {item?.images.map((image: any) => (
                      <div
                        key={image.id}
                        className="img-cont-mb"
                        style={{ width: '100%', height: 'auto' }}>
                        <ImageField
                          key={image.id}
                          style={{
                            borderRadius: '10px',
                          }}
                          loading="eager"
                          prefixCls="custom-image-preview"
                          value={image.image}
                          onClick={() =>
                            logEvent(analytics, 'space_image_click', {
                              venue_id: id,
                              space_id: item.id,
                              image_id: image.id,
                            })
                          }
                        />
                      </div>
                    ))}
                  </Masonry>
                </div>
                <div className="flex-container">
                  <div className="flex-item">
                    <Title level={4}>Description</Title>
                    {parse(item?.description ?? '')}
                  </div>
                  <div className="flex-item">
                    <AttributeList id={item?.id} />
                  </div>
                </div>
              </Card>
            )}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Packages" key="packages">
          <Row gutter={[16, 16]}>
            {isOverallPackagesLoading && (
              <div className="row h-center v-center">
                <Spin />
              </div>
            )}
            {!isOverallPackagesLoading &&
              overallPackages?.data
                .sort((a, b) => {
                  if (a.total === null && b.total === null) return 0
                  if (a.total === null) return 1
                  if (b.total === null) return -1
                  return a.total - b.total
                })
                .map((pkg) => (
                  <Col
                    key={pkg.id}
                    span={8}
                    style={{
                      display: 'flex',
                      flex: 1,
                    }}>
                    <Card
                      className="overall-packages"
                      cover={
                        <Image
                          alt="example"
                          src={pkg.thumbnail}
                          height={400}
                          onClick={() =>
                            logEvent(analytics, 'package_image_click', {
                              venue_id: id,
                              package_id: pkg.id,
                            })
                          }
                        />
                      }>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}>
                        <Title style={{ color: '#6533cb' }} level={2}>
                          {pkg.package_type}
                        </Title>
                        <PriceField
                          showDecimal={false}
                          style={{ fontSize: '26px', fontWeight: 'bold' }}
                          value={pkg.total ?? 0}
                        />
                        <Text italic style={{ fontSize: '12px' }}>
                          {pkg.subheading}
                        </Text>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          justifyContent: 'space-between',
                          marginTop: '8px',
                        }}>
                        <Tag color="orange">
                          <Text style={{ color: '#f68e24', fontSize: '16px' }}>
                            Venue
                          </Text>
                          <PriceField
                            showDecimal={false}
                            style={{ color: '#f68e24', fontSize: '18px' }}
                            value={pkg.venue_price ?? 0}
                          />
                        </Tag>
                        <Tag color="orange">
                          <Text style={{ color: '#f68e24', fontSize: '14px' }}>
                            Decor
                          </Text>
                          <PriceField
                            showDecimal={false}
                            style={{ color: '#f68e24', fontSize: '16px' }}
                            value={pkg.decor_price ?? 0}
                          />
                        </Tag>
                        <Tag color="orange">
                          <Text style={{ color: '#f68e24', fontSize: '14px' }}>
                            Catering
                          </Text>
                          <PriceField
                            showDecimal={false}
                            style={{ color: '#f68e24', fontSize: '16px' }}
                            value={pkg.catering_price ?? 0}
                          />
                        </Tag>
                        <Tag color="orange">
                          <Text style={{ color: '#f68e24', fontSize: '14px' }}>
                            Photography
                          </Text>
                          <PriceField
                            showDecimal={false}
                            style={{ color: '#f68e24', fontSize: '16px' }}
                            value={pkg.photography_price ?? 0}
                          />
                        </Tag>
                      </div>
                    </Card>
                  </Col>
                ))}
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Decor" key="decor">
          {isServicePackagesLoading && (
            <div className="row h-center v-center">
              <Spin />
            </div>
          )}
          <Tabs centered type="line" tabPosition="left">
            <Tabs.TabPane
              tab={ServicePackageType.VALUE}
              key={ServicePackageType.VALUE}>
              {servicePackages?.data
                .filter(
                  (sp) =>
                    sp.service === settingsData?.data.decor &&
                    sp.package_type === ServicePackageType.VALUE,
                )
                .map((sp) => (
                  <DecorPackage
                    key={sp.id}
                    packageId={sp.package as BaseKey}
                    onClick={() =>
                      logEvent(analytics, 'decor_package_click', {
                        venue_id: id,
                        package_id: sp.package,
                        package_type: ServicePackageType.VALUE,
                      })
                    }
                  />
                ))}
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={ServicePackageType.DELIGHT}
              key={ServicePackageType.DELIGHT}>
              {servicePackages?.data
                .filter(
                  (sp) =>
                    sp.service === settingsData?.data.decor &&
                    sp.package_type === ServicePackageType.DELIGHT,
                )
                .map((sp) => (
                  <DecorPackage
                    key={sp.id}
                    packageId={sp.package as BaseKey}
                    onClick={() =>
                      logEvent(analytics, 'decor_package_click', {
                        venue_id: id,
                        package_id: sp.package,
                        package_type: ServicePackageType.DELIGHT,
                      })
                    }
                  />
                ))}
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={ServicePackageType.LUXURY}
              key={ServicePackageType.LUXURY}>
              {servicePackages?.data
                .filter(
                  (sp) =>
                    sp.service === settingsData?.data.decor &&
                    sp.package_type === ServicePackageType.LUXURY,
                )
                .map((sp) => (
                  <DecorPackage
                    key={sp.id}
                    packageId={sp.package as BaseKey}
                    onClick={() =>
                      logEvent(analytics, 'decor_package_click', {
                        venue_id: id,
                        package_id: sp.package,
                        package_type: ServicePackageType.LUXURY,
                      })
                    }
                  />
                ))}
            </Tabs.TabPane>
          </Tabs>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Photography" key="photography">
          <Tabs centered type="line" tabBarGutter={16} tabPosition="left">
            <Tabs.TabPane
              tab={ServicePackageType.VALUE}
              key={ServicePackageType.VALUE}>
              {isServicePackagesLoading && (
                <div className="row h-center v-center">
                  <Spin tip="Loading" />
                </div>
              )}
              {!isServicePackagesLoading &&
                servicePackages?.data
                  .filter(
                    (sp) =>
                      sp.service === settingsData?.data.photography &&
                      sp.package_type === ServicePackageType.VALUE,
                  )
                  .map((sp) => (
                    <PhotographyPackage
                      key={sp.id}
                      packageId={sp.package as BaseKey}
                      onClick={() =>
                        logEvent(analytics, 'photography_package_click', {
                          venue_id: id,
                          package_id: sp.package,
                          package_type: ServicePackageType.VALUE,
                        })
                      }
                    />
                  ))}
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={ServicePackageType.DELIGHT}
              key={ServicePackageType.DELIGHT}>
              {servicePackages?.data
                .filter(
                  (sp) =>
                    sp.service === settingsData?.data.photography &&
                    sp.package_type === ServicePackageType.DELIGHT,
                )
                .map((sp) => (
                  <PhotographyPackage
                    key={sp.id}
                    packageId={sp.package as BaseKey}
                    onClick={() =>
                      logEvent(analytics, 'photography_package_click', {
                        venue_id: id,
                        package_id: sp.package,
                        package_type: ServicePackageType.DELIGHT,
                      })
                    }
                  />
                ))}
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={ServicePackageType.LUXURY}
              key={ServicePackageType.LUXURY}>
              {servicePackages?.data
                .filter(
                  (sp) =>
                    sp.service === settingsData?.data.photography &&
                    sp.package_type === ServicePackageType.LUXURY,
                )
                .map((sp) => (
                  <PhotographyPackage
                    key={sp.id}
                    packageId={sp.package as BaseKey}
                    onClick={() =>
                      logEvent(analytics, 'photography_package_click', {
                        venue_id: id,
                        package_id: sp.package,
                        package_type: ServicePackageType.LUXURY,
                      })
                    }
                  />
                ))}
            </Tabs.TabPane>
          </Tabs>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Catering" key="catering">
          <Tabs centered type="line" tabBarGutter={16} tabPosition="left">
            <Tabs.TabPane
              tab={ServicePackageType.VALUE}
              key={ServicePackageType.VALUE}>
              {!isServicePackagesLoading &&
                servicePackages?.data
                  .filter(
                    (sp) =>
                      sp.service === settingsData?.data.catering &&
                      sp.package_type === ServicePackageType.VALUE,
                  )
                  .map((sp) => (
                    <CateringPackage
                      key={sp.id}
                      packageId={sp.package as BaseKey}
                      onClick={() =>
                        logEvent(analytics, 'catering_package_click', {
                          venue_id: id,
                          package_id: sp.package,
                          package_type: ServicePackageType.VALUE,
                        })
                      }
                    />
                  ))}
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={ServicePackageType.DELIGHT}
              key={ServicePackageType.DELIGHT}>
              {!isServicePackagesLoading &&
                servicePackages?.data
                  .filter(
                    (sp) =>
                      sp.service === settingsData?.data.catering &&
                      sp.package_type === ServicePackageType.DELIGHT,
                  )
                  .map((sp) => (
                    <CateringPackage
                      key={sp.id}
                      packageId={sp.package as BaseKey}
                      onClick={() =>
                        logEvent(analytics, 'catering_package_click', {
                          venue_id: id,
                          package_id: sp.package,
                          package_type: ServicePackageType.DELIGHT,
                        })
                      }
                    />
                  ))}
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={ServicePackageType.LUXURY}
              key={ServicePackageType.LUXURY}>
              {!isServicePackagesLoading &&
                servicePackages?.data
                  .filter(
                    (sp) =>
                      sp.service === settingsData?.data.catering &&
                      sp.package_type === ServicePackageType.LUXURY,
                  )
                  .map((sp) => (
                    <CateringPackage
                      key={sp.id}
                      packageId={sp.package as BaseKey}
                      onClick={() =>
                        logEvent(analytics, 'catering_package_click', {
                          venue_id: id,
                          package_id: sp.package,
                          package_type: ServicePackageType.LUXURY,
                        })
                      }
                    />
                  ))}
            </Tabs.TabPane>
          </Tabs>
        </Tabs.TabPane>
      </Tabs>
    </Card>
  )
}

export const AttributeList = (props: { id: any }) => {
  const { data: attributes, isLoading: isAtrributesLoading } =
    useList<IAttributeValueDetail>({
      resource: `cms/product/${props?.id}/get_attribute_values/`,
    })
  const [rightTableData, setRightTableData] = useState<IAttributeValueDetail[]>(
    [],
  )

  useEffect(() => {
    if (attributes) {
      let rightData = attributes.data?.filter(
        (value: IAttributeValueDetail) => !value.attribute.show_left,
      )

      setRightTableData(rightData)
    }
  }, [attributes])

  return (
    <>
      <Title level={4}>Characteristics</Title>
      <Table
        loading={isAtrributesLoading}
        dataSource={rightTableData?.sort((a, b) => a.order - b.order)}
        size="small"
        style={{
          background: 'transparent',
          border: '1px solid #f68e24',
          borderRadius: '8px',
        }}
        showHeader={false}
        pagination={false}>
        <Table.Column dataIndex={['attribute', 'name']} />
        <Table.Column<IAttributeValueDetail>
          render={(_, record) => {
            if (record.value_options.length)
              return record.value_options.map((option, index) => (
                <Typography.Text key={option.id}>
                  {option.option.name}
                  {!!(index + 1 < record.value_options.length) && ', '}
                </Typography.Text>
              ))
            else return <Typography.Text>{record.value}</Typography.Text>
          }}
        />
      </Table>
    </>
  )
}

export const VenueTitleSection = (props: { venue?: IVenue }) => {
  return (
    <div>
      <Title level={3}>{props.venue?.name}</Title>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
        <IoLocationOutline style={{ marginRight: 8 }} />
        <Text>
          {props.venue?.line_1}, {props.venue?.line_2}, {props.venue?.city},{' '}
          {props.venue?.state} - {props.venue?.pincode}
        </Text>
      </div>
    </div>
  )
}

export const DecorTabPane = (props: {
  servicePackages: IServicePackage[]
  settingsData: ISetting
  name: string
  key: string
}) => {
  return (
    <Tabs.TabPane tab={props.name} key={props.key}>
      {props.servicePackages
        .filter(
          (sp) =>
            sp.service === props.settingsData.decor &&
            sp.package_type === props.name,
        )
        .map((sp) => (
          <DecorPackage
            key={sp.id}
            packageId={sp.package as BaseKey}
            onClick={() =>
              logEvent(analytics, 'decor_package_click', {
                package_id: sp.package,
                package_type: props.name,
              })
            }
          />
        ))}
    </Tabs.TabPane>
  )
}

export const ImageCarousel = ({
  images,
  width,
  height,
  style,
  displayIndex,
  className,
}: {
  images: any[]
  width?: number
  height?: number
  style?: React.CSSProperties
  displayIndex?: number
  className?: string
  badges?: { visible?: boolean; icon: ReactElement }[]
}) => {
  const [visible, setVisible] = useState(false)
  const [current, setCurrent] = useState(displayIndex ?? 0)

  const handleImageClick = (index: number) => {
    setCurrent(index)
    setVisible(true)
    logEvent(analytics, 'image_carousel_click', {
      image_id: images[index].id,
    })
  }

  if (images && images.length) {
    return (
      <>
        <div className="image-container">
          <Image
            preview={{ visible: false }}
            width={width}
            height={height}
            style={{
              borderRadius: '10px',
              ...style,
            }}
            src={
              images[displayIndex ?? 0].media ?? images[displayIndex ?? 0].image
            }
            onClick={() => handleImageClick(current)}
          />
        </div>
        <div style={{ display: 'none' }}>
          <Image.PreviewGroup
            preview={{
              visible,
              onVisibleChange: (vis) => setVisible(vis),
              current: current,
              onChange: (index) => setCurrent(index), // Update current index on change
            }}>
            {images.map((image, index) => (
              <Image
                key={image.id}
                loading="eager"
                style={{
                  borderRadius: '10px',
                }}
                src={image.media ?? image.image}
                onClick={() => handleImageClick(index)}
              />
            ))}
          </Image.PreviewGroup>
        </div>
      </>
    )
  }

  return <Image src={'/product_placeholder.png'} />
}

export const VenueImageGrid: React.FC<{
  media: IVenueMedia[]
}> = ({ media }) => {
  const primary = media.find((_value, idx) => idx === 0)
  const otherMedia = media.filter((_val, idx) => idx !== 0)

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'stretch',
      }}>
      <div style={{ flex: '0.4 1 0%', marginRight: '15px' }}>
        <ImageCarousel images={media} />
      </div>
      <div style={{ flex: '0.6 1 15%' }}>
        <Masonry
          breakpointCols={{
            default: 5,
            2560: 5,
            1920: 5,
            1600: 4,
            1200: 5,
            992: 4,
            768: 3,
          }}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column">
          {otherMedia?.map((media, index) => (
            <div
              key={media?.id}
              className="img-cont-mb"
              style={{ width: '100%', height: 'auto' }}>
              <ImageCarousel
                height={145}
                images={otherMedia}
                displayIndex={index}
              />
            </div>
          ))}
        </Masonry>
      </div>
    </div>
  )
}

export const VenueMedia = (props: { venueId: BaseKey }) => {
  const { data: mediaSection } = useList<IMediaSection>({
    resource: MEDIA_SECTION_URL,
    filters: [
      { field: 'venue', operator: 'eq', value: props.venueId },
      { field: 'expand', operator: 'eq', value: 'section_medias' },
    ],
  })

  const { listProps: executedImageListProps, queryResult: executedImages } =
    useSimpleList<IExecutedImage>({
      resource: EXECUTED_IMAGE_URL,
      pagination: {
        pageSize: 30,
      },
      filters: {
        permanent: [{ field: 'venue', operator: 'in', value: props.venueId }],
      },
    })

  const { modalProps: photoModalProps, show: showPhotos } = useModal()
  const { modalProps: videoModalProps, show: showVideos } = useModal()
  const { modalProps: executedImageModalProps, show: showExecutedImage } =
    useModal()

  const handleModalOpen = (type: string) => {
    logEvent(analytics, 'modal_open', {
      venue_id: props.venueId,
      modal_type: type,
    })
  }

  return (
    <>
      <div style={{ marginBottom: 16 }}>
        <Space>
          <Button
            type="default"
            size="large"
            style={{
              backgroundColor: '#fbe7d4',
              borderColor: '#f68e24',
              color: '#f68e24',
            }}
            onClick={() => {
              showPhotos()
              handleModalOpen('Photo Walkthrough')
            }}>
            Photo Walkthrough
          </Button>
          <Button
            type="default"
            size="large"
            style={{
              backgroundColor: '#fbe7d4',
              borderColor: '#f68e24',
              color: '#f68e24',
            }}
            onClick={() => {
              showVideos()
              handleModalOpen('Video Walkthrough')
            }}>
            Video Walkthrough
          </Button>
          <Button
            type="default"
            size="large"
            style={{
              backgroundColor: '#fbe7d4',
              borderColor: '#f68e24',
              color: '#f68e24',
            }}
            onClick={() => {
              showExecutedImage()
              handleModalOpen('Meragi Executed')
            }}>
            Meragi Executed
          </Button>
        </Space>
        <Modal
          {...photoModalProps}
          destroyOnClose
          footer={null}
          title="Photo Walkthrough">
          <Space direction="vertical">
            {mediaSection?.data
              .filter((ms) => ms.media_type === MediaType.IMAGE)
              .map((ms) => (
                <Card key={ms.id}>
                  <Title level={4}>{ms.name}</Title>
                  <div style={{ flex: 0.6 }}>
                    <Masonry
                      breakpointCols={{
                        default: 5,
                        2560: 5,
                        1920: 5,
                        1600: 4,
                        1200: 5,
                        992: 4,
                        768: 3,
                      }}
                      className="my-masonry-grid"
                      columnClassName="my-masonry-grid_column">
                      {ms.section_medias.map((sm, index) => (
                        <div
                          key={sm.id}
                          className="img-cont-mb"
                          style={{ width: '100%', height: 'auto' }}>
                          <ImageCarousel
                            height={145}
                            images={ms.section_medias}
                            displayIndex={index}
                          />
                        </div>
                      ))}
                    </Masonry>
                  </div>
                </Card>
              ))}
          </Space>
        </Modal>
        <Modal
          {...videoModalProps}
          destroyOnClose
          footer={null}
          title="Video Walkthrough">
          {mediaSection?.data
            .filter((ms) => ms.media_type === MediaType.VIDEO)
            .map((ms) => (
              <Card key={ms.id}>
                <Title level={4}>{ms.name}</Title>
                {ms.section_medias.map((sm) => (
                  <ReactPlayer
                    pip={false}
                    config={{}}
                    controls
                    key={sm.id}
                    url={sm.media || sm.link}
                    style={{ padding: '10px' }}
                    height={500}
                    onClick={() =>
                      logEvent(analytics, 'video_walkthrough_click', {
                        venue_id: props.venueId,
                        media_section_id: ms.id,
                        media_id: sm.id,
                      })
                    }
                  />
                ))}
              </Card>
            ))}
        </Modal>
        <Modal
          {...executedImageModalProps}
          destroyOnClose
          footer={null}
          title="Meragi Executed">
          <div style={{ flex: 0.6 }}>
            <List {...executedImageListProps} size="small">
              <Masonry
                breakpointCols={{
                  default: 5,
                  2560: 5,
                  1920: 5,
                  1600: 4,
                  1200: 5,
                  992: 4,
                  768: 3,
                }}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column">
                {executedImages?.data?.data.map((image, index) => (
                  <div
                    key={image.id}
                    className="img-cont-mb"
                    style={{ width: '100%', height: 'auto' }}>
                    <ImageCarousel
                      images={executedImages?.data?.data}
                      displayIndex={index}
                    />
                  </div>
                ))}
              </Masonry>
            </List>
          </div>
        </Modal>
      </div>
      <Space>
        <VenueImageGrid
          media={
            mediaSection?.data.find((ms) => ms.money_shot)?.section_medias ?? []
          }
        />
      </Space>
    </>
  )
}

export const DecorPackage = (props: {
  packageId: BaseKey
  onClick?: () => void
}) => {
  const { data: shortlistPackageData, isLoading: isShortlistPackageLoading } =
    useOne<IShortlistPackage>({
      resource: SHORTLIST_PACKAGE_URL,
      id: props.packageId,
    })
  return (
    <Card loading={isShortlistPackageLoading} onClick={props.onClick}>
      <Tabs type="card">
        {!isShortlistPackageLoading &&
          shortlistPackageData?.data.package_details?.map((pd) => (
            <Tabs.TabPane key={pd.id} tab={pd.section.name}>
              <>
                <Text style={{ color: '#6533cb' }}>Total : </Text>{' '}
                <PriceField
                  showDecimal={false}
                  value={pd.total_price ?? 0}
                  style={{
                    fontSize: '26px',
                    fontWeight: 'bold',
                    color: '#6533cb',
                  }}
                />
                <div>
                  <Masonry
                    breakpointCols={{
                      default: 5,
                      2560: 5,
                      1920: 5,
                      1600: 4,
                      1200: 5,
                      992: 4,
                      768: 3,
                    }}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column">
                    {pd.items?.map((item) => (
                      <div
                        key={item.id}
                        className="img-cont-mb"
                        style={{ width: '100%', height: 'auto' }}>
                        <ImageField
                          key={item.id}
                          value={item.product?.images[0].image}
                          style={{
                            borderRadius: '10px',
                          }}
                          loading="eager"
                          prefixCls="custom-image-preview"
                          onClick={() =>
                            logEvent(analytics, 'decor_item_click', {
                              package_id: props.packageId,
                              item_id: item.id,
                            })
                          }
                        />
                      </div>
                    ))}
                  </Masonry>
                </div>
              </>
            </Tabs.TabPane>
          ))}
      </Tabs>
    </Card>
  )
}

export const PhotographyPackage = (props: {
  packageId: BaseKey
  onClick?: () => void
}) => {
  const { data: shortlistPackageData, isLoading: isShortlistPackageLoading } =
    useOne<IShortlistPackage>({
      resource: SHORTLIST_PACKAGE_URL,
      id: props.packageId,
    })
  return (
    <Card loading={isShortlistPackageLoading} onClick={props.onClick}>
      <Tabs type="card">
        {shortlistPackageData?.data.package_details?.map((pd) => (
          <Tabs.TabPane key={pd.id} tab={pd.section.name}>
            <>
              <Text style={{ color: '#6533cb' }}>Total : </Text>{' '}
              <PriceField
                showDecimal={false}
                value={pd.total_price ?? 0}
                style={{
                  fontSize: '26px',
                  fontWeight: 'bold',
                  color: '#6533cb',
                }}
              />
              <Row gutter={[16, 16]}>
                {pd.items?.map((item) => (
                  <Col span={8} key={item.id}>
                    <Card
                      cover={
                        <Image
                          key={item.id}
                          src={item.product?.images[0].image}
                          onClick={() =>
                            logEvent(analytics, 'photography_item_click', {
                              package_id: props.packageId,
                              item_id: item.id,
                            })
                          }
                        />
                      }>
                      <Card.Meta title={item.product?.name} />
                    </Card>
                  </Col>
                ))}
              </Row>
            </>
          </Tabs.TabPane>
        ))}
      </Tabs>
    </Card>
  )
}

export const CateringPackage = (props: {
  packageId: BaseKey
  onClick?: () => void
}) => {
  const { data: cateringPackage, isLoading: isCateringPackageLoading } =
    useOne<IShortlistPackage>({
      resource: SHORTLIST_PACKAGE_URL,
      id: props.packageId,
    })
  return (
    <Tabs type="card" className="custom-tabs-catering">
      {isCateringPackageLoading && (
        <div className="row h-center v-center">
          <Spin />
        </div>
      )}
      {!isCateringPackageLoading &&
        cateringPackage?.data.sessions.map((session: ISession) => (
          <Tabs.TabPane tab={session?.name} key={session?.id}>
            <Descriptions
              title={
                <>
                  <Text style={{ color: '#6533cb' }}>Total : </Text>{' '}
                  <PriceField
                    showDecimal={false}
                    value={session?.session_total ?? 0}
                    style={{
                      fontSize: '26px',
                      fontWeight: 'bold',
                      color: '#6533cb',
                    }}
                  />
                  <Text> - Pax : {session.pax}</Text>
                </>
              }
            />
            {session?.menus.map((menu: IMenu) => (
              <MenuPackage menuId={menu.id} key={menu.id} />
            ))}
          </Tabs.TabPane>
        ))}
    </Tabs>
  )
}

export const MenuPackage = (props: { menuId: BaseKey }) => {
  const { data: menuPackage, isLoading: isMenuPackageLoading } = useOne<IMenu>({
    resource: MENU_URL,
    id: props.menuId,
  })

  return (
    <Card
      key={props?.menuId}
      loading={isMenuPackageLoading}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%', // Ensure the card takes up the full height
        textAlign: 'center',
        border: '8px double #bb7212',
        backgroundColor: 'transparent',
      }}>
      <Title level={2} style={{ color: '#f68e24' }}>
        {menuPackage?.data?.name}
      </Title>
      <MenuCourse menuCourseId={menuPackage?.data?.id as BaseKey} />
    </Card>
  )
}

export const MenuCourse = (props: { menuCourseId: BaseKey }) => {
  const { data: menuCourse, isLoading: isMenuCourseLoading } =
    useList<IShortlistPackage>({
      resource: MENU_COURSE_URL,
      filters: [
        { field: 'menu', operator: 'eq', value: props.menuCourseId },
        { field: 'expand', operator: 'eq', value: 'course' },
      ],
      hasPagination: false,
    })

  return (
    <Card
      bordered={false}
      style={{
        boxShadow: 'none',
        textAlign: 'center',
        backgroundColor: 'transparent',
      }}
      key={props?.menuCourseId}
      loading={isMenuCourseLoading}>
      {menuCourse?.data.map((course: any) => (
        <div key={course.id} style={{ marginBottom: '16px' }}>
          <Title level={4} style={{ color: '#a03f07' }}>
            {course.course.name}
          </Title>
          <CourseDish courseDishId={course.id as BaseKey} key={course.id} />
        </div>
      ))}
    </Card>
  )
}

export const CourseDish = (props: { courseDishId: BaseKey }) => {
  const { data: courseDish } = useList<IShortlistPackage>({
    resource: COURSE_DISH_URL,
    filters: [
      { field: 'course', operator: 'eq', value: props.courseDishId },
      { field: 'expand', operator: 'eq', value: 'dish' },
    ],
    hasPagination: false,
  })

  return (
    <div key={props?.courseDishId} style={{ textAlign: 'center' }}>
      {courseDish?.data.map((dish: any) => (
        <div key={dish.id} style={{ marginBottom: '8px' }}>
          <Title level={5} style={{ color: '#e68508' }}>
            {dish.dish.name}
          </Title>
        </div>
      ))}
    </div>
  )
}
