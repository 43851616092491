import { DateField, Show } from "@refinedev/antd";
import { Divider, Space, Typography } from "antd";
import { useShow } from "@refinedev/core";
import { CheckAccess } from 'components/checkAccess'
import { PriceField } from 'components/field'
import { IPayment } from 'interfaces/payment'
import ReactJson from 'react-json-view'

const { Title, Text, Link } = Typography

export const PaymentShow: React.FC = () => {
  const { queryResult } = useShow<IPayment>()
  const { data, isLoading } = queryResult
  const record = data?.data

  return (
    <CheckAccess resource="access_payments">
      <Show isLoading={isLoading}>
        <div className="row">
          <Space direction="vertical">
            <Text>
              Project ID:{' '}
              <Link
                href={`/package_tool/events/show/${record?.event}`}
                target="_blank">
                {record?.event}
              </Link>
            </Text>
            <Text>CF order ID: {record?.order_id}</Text>
            <Text>
              Package:{' '}
              <Link
                href={`/package_tool/shortlist_packages/edit/${record?.package}`}
                target="_blank">
                {record?.package}
              </Link>
            </Text>
            <Text>
              Payment Link:{' '}
              <Link href={record?.link_url} target="_blank">
                {record?.link_url}
              </Link>
            </Text>
          </Space>
          <div>
            <Text>{record?.status}</Text>
            <br />
            <PriceField value={record?.amount ?? 0} />
          </div>
        </div>

        <Divider />
        <div className="row">
          <Space direction="vertical">
            <Title level={4}>Customer Details</Title>
            <Text>
              Name: {record?.customer.first_name} {record?.customer.last_name}
            </Text>
            <Text>Phone Number: {record?.customer.username}</Text>
          </Space>
        </div>
        <Divider />
        <Space direction="vertical">
          <Title level={4}>Date and Time</Title>
          <Text>
            Created At: <DateField value={record?.created_at} format="LLL" />
          </Text>
          <Text>
            Updated At: <DateField value={record?.updated_at} format="LLL" />
          </Text>
        </Space>
        <Divider />
        <Title level={4}>Raw Details</Title>
        <ReactJson
          src={record?.raw_response ?? {}}
          collapsed
          displayDataTypes={false}
        />
      </Show>
    </CheckAccess>
  )
}
