import { Edit, useSelect } from "@refinedev/antd";
import { Drawer, Form, Input, Select, Switch, Tabs } from "antd";
import { useCan } from "@refinedev/core";
import { useSelectWithSearch } from 'components/hooks'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { ICustomer, IEvent } from 'interfaces/customer'
import { IShortlistPackage, IUser } from 'interfaces/shortlistPackage'
import { IStaff } from 'interfaces/staff'

import React from 'react'
import ReactQuill from 'react-quill'
import { STAFF_URL } from 'urls'

export const PackageDrawerForm: React.FC<{
  drawerProps: any
  saveButtonProps: any
  formProps: any
  id: string
  customer: IUser
  initialData: IShortlistPackage
}> = ({
  drawerProps,
  saveButtonProps,
  formProps,
  id,
  customer,
  initialData,
}) => {
    const { selectProps: customerSelectProps } = useSelectWithSearch<ICustomer>({
      resource: 'package_tool/customers',
      optionLabel: 'username',
      optionValue: 'id',
      defaultValue: customer && customer.id,
    })

    const { selectProps: eventSelectProps } = useSelect<IEvent>({
      resource: `package_tool/customers/${customer && customer.id}/events`,
      optionLabel: 'name',
      optionValue: 'id',

      queryOptions: {
        enabled: !!customer,
      },

      pagination: {
        mode: "server"
      }
    })

    const { data: finalizePackage } = useCan({
      resource: 'finalize_package',
      action: '',
    })
    const { data: cancelPackage } = useCan({
      resource: 'cancel_package',
      action: '',
    })
    const { data: changeConsultant } = useCan({
      resource: 'change_consultant',
      action: '',
    })

    return (
      <div>
        <Drawer {...drawerProps}>
          <Edit
            saveButtonProps={saveButtonProps}
            breadcrumb={null}
            headerButtons={<></>}
            title="Edit Package Details"
            goBack={false}
            recordItemId={id}>
            <Form
              {...formProps}
              layout="vertical"
              initialValues={{
                ...initialData,
                consultant: initialData.consultant
                  ? initialData.consultant.id
                  : null,
                project_manager: initialData.project_manager
                  ? initialData.project_manager.id
                  : null,
                event: initialData.event ? initialData.event.id : null,
                customer: initialData.customer ? initialData.customer.id : null,
              }}>
              <Tabs>
                <Tabs.TabPane tab={'Details'} key={'details'} closable={false}>
                  <Form.Item
                    name="customer"
                    label="Customer"
                    rules={[{ required: true }]}>
                    <Select disabled={initialData.is_cancelled} {...customerSelectProps} />
                  </Form.Item>
                  <Form.Item
                    name="event"
                    label="Event"
                    rules={[{ required: true }]}>
                    <Select disabled={initialData.is_cancelled} {...eventSelectProps} />
                  </Form.Item>

                  <Form.Item
                    label="Project"
                    name="name"
                    rules={[{ required: true }]}>
                    <Input disabled={initialData.is_cancelled} />
                  </Form.Item>
                  <Form.Item label="Discount Amount" name="discount_amount">
                    <Input disabled={initialData.is_cancelled} />
                  </Form.Item>
                  <Form.Item
                    label="Description"
                    name="description"
                    initialValue={''}>
                    <ReactQuill theme="snow" />
                  </Form.Item>
                  {/* Package Setting */}
                  <Form.Item name="consultant" label="Consultant">
                    <SelectWithDefault<IStaff>
                      useSelectProps={{ resource: STAFF_URL }}
                      id="id"
                      disabled={!changeConsultant?.can || initialData.is_cancelled}
                      renderOptions={(staffs) =>
                        staffs?.map((staff) => (
                          <Select.Option key={staff.id} value={staff.id}>
                            {staff.first_name} {staff.last_name} - (
                            {staff.username})
                          </Select.Option>
                        ))
                      }
                    />
                  </Form.Item>
                  <Form.Item name="project_manager" label="Project Manager">
                    <SelectWithDefault<IStaff>
                      useSelectProps={{ resource: STAFF_URL }}
                      disabled={!finalizePackage?.can || initialData.is_cancelled}
                      renderOptions={(staffs) =>
                        staffs?.map((staff) => (
                          <Select.Option key={staff.id} value={staff.id}>
                            {staff.first_name} {staff.last_name} - (
                            {staff.username})
                          </Select.Option>
                        ))
                      }
                    />
                  </Form.Item>
                </Tabs.TabPane>
                <Tabs.TabPane tab={'Setting'} key={'setings'} closable={false}>
                  <Form.Item
                    label="Is Cancelled"
                    name="is_cancelled"
                    valuePropName="checked">
                    <Switch disabled={!cancelPackage?.can} />
                  </Form.Item>
                  <Form.Item
                    label="Is Selected"
                    name="is_selected"
                    valuePropName="checked">
                    <Switch disabled={initialData.is_cancelled} />
                  </Form.Item>
                  <Form.Item
                    label="Is Finalized"
                    name="is_finalized"
                    valuePropName="checked">
                    <Switch disabled={!finalizePackage?.can || initialData.is_cancelled} />
                  </Form.Item>
                  <Form.Item
                    label="Is Link Active"
                    name="is_link_active"
                    valuePropName="checked">
                    <Switch disabled={initialData.is_cancelled} />
                  </Form.Item>
                  <Form.Item
                    label="Is individual item price shown"
                    name="is_individual_price_shown"
                    valuePropName="checked"
                    initialValue={true}>
                    <Switch disabled={initialData.is_cancelled} />
                  </Form.Item>
                </Tabs.TabPane>
              </Tabs>
            </Form>
          </Edit>
        </Drawer>
      </div>
    )
  }
