import { useModal } from '@refinedev/antd'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import { Modal, Tooltip, Space, Image, Descriptions, Collapse } from 'antd'
import { ILeadProfile } from 'interfaces/crm'
import React from 'react'
import Markdown from 'markdown-to-jsx'

export const ProfileModal: React.FC<{
  profile: ILeadProfile
  leadName: string
  leadEmail: string
  leadPhoneNumber: string
}> = ({ profile, leadEmail, leadName, leadPhoneNumber }) => {
  const { show, modalProps } = useModal()

  return (
    <>
      <Tooltip title="Lead Profile Information">
        <Icons.UserOutlined style={{ color: 'gold' }} onClick={show} />
      </Tooltip>
      <Modal
        {...modalProps}
        title={
          <>
            {`${leadName} (Profile)`}{' '}
            {profile?.linkedin_url && (
              <Icons.LinkedinOutlined
                style={{ color: 'blue' }}
                onClick={() =>
                  window.open(profile.linkedin_url?.toString(), '_blank')
                }
              />
            )}
          </>
        }
        footer={null}>
        <Space
          direction="vertical"
          style={{
            width: '100%',
          }}>
          <Space>
            {profile?.photo && (
              <Image src={profile?.photo} height={200} width={200} />
            )}
            <Markdown>{profile?.summary ?? ''}</Markdown>
          </Space>
          <Collapse>
            <Collapse.Panel key={'detailed-profile'} header="Lead Details">
              <Descriptions layout={'horizontal'} column={3}>
                {/* 1st Row */}
                <Descriptions.Item label="Age">
                  {profile?.age?.toString() ?? ''}
                </Descriptions.Item>
                <Descriptions.Item label="Professional Level">
                  {profile?.professional_level?.toString() ?? ''}
                </Descriptions.Item>
                <Descriptions.Item label={'Education Field'}>
                  {profile.education_field?.toString() ?? ''}
                </Descriptions.Item>
                {/* 2nd Row */}
                <Descriptions.Item label="Gender">
                  {profile?.gender?.toString() ?? ''}
                </Descriptions.Item>
                <Descriptions.Item label={'Job Title'}>
                  {profile.job_title?.toString() ?? ''}
                </Descriptions.Item>
                <Descriptions.Item label={'Education Institute'}>
                  {profile.education_institute?.toString() ?? ''}
                </Descriptions.Item>
                {/* 3rd Row */}
                <Descriptions.Item label={'Location'}>
                  {profile.location?.toString() ?? ''}
                </Descriptions.Item>
                <Descriptions.Item label={'Company Name'}>
                  {profile.company_name?.toString() ?? ''}
                </Descriptions.Item>
                <Descriptions.Item label={'Phone'}>
                  {leadPhoneNumber}
                </Descriptions.Item>
                {/* 4th Row */}
                <Descriptions.Item label={'Languages'}>
                  {profile.languages?.join(', ') ?? ''}
                </Descriptions.Item>
                <Descriptions.Item label={'Company Industry'}>
                  {profile.company_industry?.toString() ?? ''}
                </Descriptions.Item>
                <Descriptions.Item label={'Email'}>
                  {leadEmail}
                </Descriptions.Item>
              </Descriptions>
            </Collapse.Panel>
          </Collapse>
        </Space>
      </Modal>
    </>
  )
}
