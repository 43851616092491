import { DateField, useForm, useModalForm } from '@refinedev/antd'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import {
  List as AntdList,
  Button,
  Descriptions,
  Divider,
  Select,
  Space,
  Spin,
  Statistic,
  Tag,
  Upload,
} from 'antd'

import { useApiUrl, useOne } from '@refinedev/core'
import useToggle from 'components/hooks/useToggle'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { IItem, IPackageDetail } from 'interfaces/shortlistPackage'
import React, { useContext, useState } from 'react'
import { SHORTLIST_PACKAGE_DETAILS_URL, VENUE_URL } from 'urls'
import { EditPackageContext, EditPackageContextType } from '../Edit'
import {
  CreateShortlistLineItem,
  ShortlistLineItem,
} from '../shortlistLineItem'
import { EditPackageDetails } from './Edit'
import { NotesInput } from './NotesInput'
import { ShortlistPackageContext } from 'contexts/shortlistPackage'

export const PackageDetailsDetail: React.FC<{
  package_detail_id: number
  refetch: any
  package: string
}> = ({ refetch, package: package_id, package_detail_id }) => {
  const apiUrl = useApiUrl()

  const [addShortlistItem, setAddShortlistItem] = useState(false)
  const { editPackageStatus, setEditPackageStatus, isFinalized } = useContext(
    EditPackageContext,
  ) as EditPackageContextType
  const _package = useContext(ShortlistPackageContext)

  const {
    data: packageDetail,
    isLoading,
    refetch: packageDetailRefetch,
    isRefetching,
  } = useOne<IPackageDetail>({
    resource: 'package_tool/shortlist_package/package_details',
    id: package_detail_id,
  })
  const packageDetailData = packageDetail?.data

  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editModalShow,
  } = useModalForm<IPackageDetail>({
    action: 'edit',
    id: packageDetailData?.id,
    resource: 'package_tool/shortlist_package/package_details',
    redirect: false,
    onMutationSuccess: () => {
      refetch()
      setEditPackageStatus(true)
    },
    warnWhenUnsavedChanges: true,
  })

  const { formProps, saveButtonProps, form } = useForm<IItem>({
    action: 'create',
    resource: 'package_tool/shortlist_package/line_items',
    redirect: false,
    onMutationSuccess: () => {
      refetch()
      packageDetailRefetch()
      form.resetFields()
      setAddShortlistItem(false)
    },
  })

  const [showUpload, toggleShowUpload] = useToggle(false)
  const [executedFileList, setExecutedFileList] = useState<any>([])

  return (
    <>
      <Spin spinning={isLoading || isRefetching}>
        {packageDetailData && (
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Space align="baseline" size="large">
                <Statistic
                  title="Event Price"
                  value={
                    packageDetailData?.total_price
                      ? '₹ ' + packageDetailData?.total_price
                      : '₹ ' + 0
                  }
                />
                <Statistic
                  title="Items Count"
                  value={
                    packageDetailData?.items
                      ? packageDetailData?.items.length
                      : 0
                  }
                />
                <Statistic
                  title="Executed Image Count"
                  value={packageDetailData?.executed_image_pkg_count ?? 0}
                />
                {!_package?.is_cancelled && <NotesInput
                  packageDetailId={packageDetailData.id}
                  count={packageDetailData.notes_count}
                />}
                {!_package?.is_cancelled && <Button type="dashed" onClick={() => toggleShowUpload()}>
                  Upload executed images
                </Button>}
              </Space>
              <Space>
                {editPackageStatus && !isFinalized && !_package?.is_cancelled && (
                  <Button
                    icon={<Icons.EditOutlined />}
                    size="small"
                    onClick={() => {
                      editModalShow(packageDetailData.id)
                    }}
                  />
                )}
              </Space>
            </div>
            {showUpload && (
              <div>
                <Upload.Dragger
                  multiple
                  action={`${apiUrl}/${SHORTLIST_PACKAGE_DETAILS_URL}/${packageDetailData.id}/upload_executed/`}
                  headers={{
                    Authorization: `Token ${localStorage.getItem('token')}`,
                  }}
                  fileList={executedFileList}
                  onChange={(info) => {
                    setExecutedFileList([...info.fileList])
                  }}
                  data={async (file) => ({ image: file })}
                  itemRender={(originNode, file, fileList) => {
                    if (file.status !== 'done') {
                      return (
                        <>
                          {file.name}{' '}
                          {file.status === 'error' ? (
                            <Icons.ExclamationCircleOutlined
                              style={{ color: 'red' }}
                            />
                          ) : (
                            <Icons.LoadingOutlined />
                          )}
                        </>
                      )
                    }
                  }}>
                  <p className="ant-upload-drag-icon">
                    <Icons.InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag files to this area to upload
                  </p>
                  <p className="ant-upload-text">
                    uploaded{' '}
                    {
                      executedFileList.filter(
                        (file: any) => file.status === 'done',
                      ).length
                    }
                    /{executedFileList.length} files
                  </p>
                </Upload.Dragger>
              </div>
            )}
            <Divider>Time and Venue</Divider>
            <Descriptions>
              {packageDetailData.date_not_decided && (
                <Descriptions.Item label="Date">
                  <Tag color="red">Not Decided</Tag>
                </Descriptions.Item>
              )}
              {!packageDetailData.date_not_decided && (
                <>
                  <Descriptions.Item label="Decor Start Date and Time">
                    <DateField
                      value={packageDetailData.decor_date_time}
                      format="LLL"
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label="Event Date">
                    <DateField
                      value={packageDetailData.date_time}
                      format="LLL"
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label="Dismantling Start Date and Time">
                    <DateField
                      value={packageDetailData?.dismantling_start_date_time}
                      format="LLL"
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label="Dismantling End Date and Time">
                    <DateField
                      value={packageDetailData?.dismantling_date_time}
                      format="LLL"
                    />
                  </Descriptions.Item>
                </>
              )}
              {packageDetailData.venue_not_decided && (
                <Descriptions.Item label="Venue">
                  <Tag color="red">Not Decided</Tag>
                </Descriptions.Item>
              )}
              {!packageDetailData.venue_not_decided && (
                <Descriptions.Item label="Venue">
                  <SelectWithDefault
                    disabled
                    bordered={false}
                    size="small"
                    useSelectProps={{
                      resource: VENUE_URL,
                      optionLabel: 'name',
                    }}
                    value={packageDetailData.venue}
                  />
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Pax">
                {packageDetailData.pax}
              </Descriptions.Item>
              <Descriptions.Item label="SPOC Contact">
                {packageDetailData.spoc_contact}
              </Descriptions.Item>
            </Descriptions>
            <Divider>Items</Divider>
            <AntdList
              itemLayout="vertical"
              size="large"
              dataSource={packageDetailData?.items}
              renderItem={(item) => (
                <div key={`${item.id}-${item.order}`}>
                  <ShortlistLineItem
                    lineItem={item}
                    key={`${item.id}-${item.order}`}
                    refetch={packageDetailRefetch}
                    refetchPackage={refetch}
                  />
                </div>
              )}
            />
            {editPackageStatus && !addShortlistItem && !_package?.is_cancelled && (
              <Button
                onClick={() => {
                  setAddShortlistItem(!addShortlistItem)
                }}
                block>
                Add Item
              </Button>
            )}
            {/*  Add new Shortlist Item */}
            {packageDetailData && editPackageStatus && (
              <EditPackageDetails
                modalProps={editModalProps}
                formProps={editFormProps}
                shortlistPackageId={package_id}
                section={packageDetailData?.section.id}
              />
            )}
            {addShortlistItem && (
              <CreateShortlistLineItem
                form={form}
                saveButtonProps={saveButtonProps}
                formProps={formProps}
                packageDetailId={packageDetailData?.id}
                cancelSave={() => {
                  setAddShortlistItem(!addShortlistItem)
                }}
              />
            )}
          </div>
        )}
      </Spin>
    </>
  )
}
