import { BaseKey, useCreate, useInvalidate } from '@refinedev/core'
import { Button, Form, Switch } from 'antd'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import React, { useState } from 'react'
import { LEAD_URL, VENUE_SUGGESTION_URL, VENUE_URL } from 'urls'
import { Create, useForm } from '@refinedev/antd'
import * as Icons from '@ant-design/icons'

const AddVenueSuggestion: React.FC<{
  leadId: BaseKey
}> = ({ leadId }) => {
  const [addVenue, setAddVenue] = useState<boolean>(false)
  const invalidate = useInvalidate()

  const { formProps, saveButtonProps, form } = useForm({
    action: 'create',
    resource: VENUE_SUGGESTION_URL,
    redirect: false,
    onMutationSuccess: () => {
      invalidate({
        resource: LEAD_URL,
        invalidates: ['list'],
      })
      setAddVenue(false)
      form.resetFields()
    },
  })

  return (
    <div>
      {addVenue ? (
        <Create
          goBack={null}
          headerButtons={
            <Button
              icon={<Icons.CloseOutlined />}
              onClick={() => {
                setAddVenue(false)
                form.resetFields()
              }}
            />
          }
          title={'Add Venue'}
          saveButtonProps={saveButtonProps}>
          <Form
            {...formProps}
            onFinish={(values) => {
              formProps?.onFinish?.({ ...values, lead: leadId })
            }}>
            <Form.Item
              label="Venue"
              name="venue"
              rules={[
                {
                  required: true,
                },
              ]}>
              <SelectWithDefault
                useSelectProps={{
                  resource: VENUE_URL,
                  optionLabel: 'name',
                }}
              />
            </Form.Item>
          </Form>
        </Create>
      ) : (
        <Button block onClick={() => setAddVenue(true)}>
          Add Venue
        </Button>
      )}
    </div>
  )
}

export default AddVenueSuggestion
