import { Edit, useForm } from '@refinedev/antd'
import { Form, Input } from 'antd'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { SERVICE_URL, SETTINGS_URL, VENDOR_URL } from 'urls'

export const EditSettings = () => {
  const { formProps, saveButtonProps } = useForm({
    resource: SETTINGS_URL,
    action: 'edit',
    id: 1,
  })

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item label="Vendor" name="vendor">
          <SelectWithDefault
            useSelectProps={{ resource: VENDOR_URL, optionLabel: 'name' }}
          />
        </Form.Item>
        <Form.Item label="Default Payment Account" name="default_account">
          <Input />
        </Form.Item>
        <Form.Item label="VPA Payment Account" name="vpa_account">
          <Input />
        </Form.Item>
        <Form.Item label="Decor Service" name="decor">
          <SelectWithDefault
            useSelectProps={{ resource: SERVICE_URL, optionLabel: 'name' }}
          />
        </Form.Item>
        <Form.Item label="Catering Service" name="catering">
          <SelectWithDefault
            useSelectProps={{ resource: SERVICE_URL, optionLabel: 'name' }}
          />
        </Form.Item>
        <Form.Item label="Photography Service" name="photography">
          <SelectWithDefault
            useSelectProps={{ resource: SERVICE_URL, optionLabel: 'name' }}
          />
        </Form.Item>
      </Form>
    </Edit>
  )
}
